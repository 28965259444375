import React, { useEffect } from "react";

import { useAuth } from "@/AuthWrapper";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import Users from "components/customer_lookup/Users";
import NotFound from "components/not_found/NotFound";
import ParagonConnect from "components/paragon/ParagonConnect";
import BiCampaignReports from "components/reports/BiCampaignReports";

import { ReactRouter6Adapter } from "../../QueryParamsReactRouter6Adapter";

const Root = () => {
  const { market: subdomain } = useParams();
  const { currentAdmin } = useAuth();
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient) {
      const ldContext = ldClient.getContext();
      ldContext.market_subdomain = subdomain;
      ldClient.identify(ldContext);
    }
  }, [ldClient, subdomain]);

  const selectedMarket = currentAdmin.marketBySubdomain(subdomain);

  if (selectedMarket === null) {
    return <NotFound />;
  }

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Routes>
        <Route path={""} element={<Navigate to={"users"} />} />
        <Route path={"users"} element={<Users selectedMarket={selectedMarket} />} />
        <Route path={"users/*"} element={<Users selectedMarket={selectedMarket} />} />
        <Route path={"reports"} element={<BiCampaignReports selectedMarket={selectedMarket} />} />
        <Route path={"reports/*"} element={<BiCampaignReports selectedMarket={selectedMarket} />} />
        <Route path={"integrations"} element={<ParagonConnect selectedMarket={selectedMarket} />} />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </QueryParamProvider>
  );
};

export default Root;
