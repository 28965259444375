import { useLocation, useNavigate } from "react-router-dom";

// TODO: sc-34282: Pretty sure this is never run anymore. This *should* be putting the search query into the browser url but it is not.
export const ReactRouter6Adapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adapter = {
    replace(location) {
      navigate(location.search || "?", { state: location.state, replace: true });
    },
    push(location) {
      navigate(location.search || "?", {
        state: location.state,
        replace: false,
      });
    },
    get location() {
      return location;
    },
  };

  return children(adapter);
};
