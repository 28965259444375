import React from "react";

import { StatusColours } from "bp-ui";
import { useTranslation } from "react-i18next";

import { getFulfilmentStatusMessage } from "components/customer_lookup/new_user/helpers";
import FormattedDate from "components/formatted_date/FormattedDate";

export const FulfilmentStatus = ({ fulfilment, timezoneOffset }) => {
  const { t } = useTranslation();
  const fulfilmentStatusMessage = getFulfilmentStatusMessage(fulfilment);
  const statusColour = StatusColours[fulfilment.status.value];

  return (
    <>
      {(fulfilmentStatusMessage || fulfilment.providerTransactionId) && (
        <div
          className={`border-0 border-l-2 pl-4 border-solid border-${statusColour || "red"} flex flex-col mt-4`}
        >
          {fulfilment.providerTransactionId && (
            <div className="text-black text-xs my-0.5">
              <span className="font-bold mr-1">
                {t("referrerProfile.referralTimeline.transactionId")}
              </span>
              {fulfilment.providerTransactionId}
            </div>
          )}
          {fulfilmentStatusMessage && (
            <>
              <div className="text-black text-xs my-0.5">
                <span className="font-bold mr-1">
                  {t("referrerProfile.referralTimeline.timeProcessed")}
                </span>
                <FormattedDate
                  timestamp={fulfilment.status.updatedAt}
                  timezoneOffset={timezoneOffset}
                  type="numeral_short_with_time"
                />
              </div>
              <div className="text-black text-xs my-0.5">
                <span className="font-bold mr-1">
                  {t("referrerProfile.referralTimeline.error")}
                </span>
                {fulfilmentStatusMessage}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
