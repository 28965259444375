import React, { Component } from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";

import Calendar from "components/reports/date_picker/date_range_selector/custom_range/calendars/calendar/Calendar";
import "components/reports/date_picker/date_range_selector/custom_range/calendars/calendars.scss";

class Calendars extends Component {
  static propTypes = {
    updateNewDateRange: PropTypes.func.isRequired,
    newStartDate: PropTypes.instanceOf(DateTime).isRequired,
    newEndDate: PropTypes.instanceOf(DateTime).isRequired,
    timezoneOffset: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentDate: props.newStartDate,
    };
  }

  prevMonth = () => {
    let { currentDate } = this.state;
    currentDate = currentDate.minus({
      months: 1,
    });

    this.setState({ currentDate: currentDate.startOf("day") });
  };

  prevYear = () => {
    let { currentDate } = this.state;
    currentDate = currentDate.minus({
      years: 1,
    });

    this.setState({ currentDate: currentDate.startOf("day") });
  };

  nextMonth = () => {
    let { currentDate } = this.state;
    currentDate = currentDate.plus({
      months: 1,
    });

    this.setState({ currentDate });
  };

  nextYear = () => {
    let { currentDate } = this.state;
    currentDate = currentDate.plus({
      years: 1,
    });

    this.setState({ currentDate });
  };

  calendarDate = (date, type) =>
    type === "first"
      ? date
      : date.plus({
          months: 1,
        });

  render() {
    const { newStartDate, newEndDate, updateNewDateRange, timezoneOffset } = this.props;
    const { currentDate } = this.state;

    return (
      <div className="calendars-wrapper">
        <Calendar
          type="first"
          monthAction={this.prevMonth}
          yearAction={this.prevYear}
          currentDate={this.calendarDate(currentDate, "first")}
          updateNewDateRange={updateNewDateRange}
          newStartDate={newStartDate}
          newEndDate={newEndDate}
          timezoneOffset={timezoneOffset}
        />
        <Calendar
          type="last"
          monthAction={this.nextMonth}
          yearAction={this.nextYear}
          currentDate={this.calendarDate(currentDate, "last")}
          updateNewDateRange={updateNewDateRange}
          newStartDate={newStartDate}
          newEndDate={newEndDate}
          timezoneOffset={timezoneOffset}
        />
      </div>
    );
  }
}

export default Calendars;
