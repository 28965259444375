import React, { useState } from "react";

import { Permissions } from "bp-ui";
import { fullName } from "helpers/Helpers";
import Friend from "models/Friend";
import Market from "models/Market";
import Referrer from "models/Referrer";
import { func, instanceOf, oneOfType } from "prop-types";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import CisUser from "components/customer_lookup/cis_user/CisUser";
import CisUserCampaigns from "components/customer_lookup/cis_user/cis_user_campaigns/CisUserCampaigns";
import CreateReferral from "components/customer_lookup/user/create_referral/CreateReferral";
import IssueReward from "components/customer_lookup/user/issue_reward/IssueReward";
import UserEdit from "components/customer_lookup/user/user_edit/UserEdit";
import UserOrders from "components/customer_lookup/user/user_orders/UserOrders";
import UserProfile from "components/customer_lookup/user/user_profile/UserProfile";
import UserUnsubscribe from "components/customer_lookup/user/user_unsubscribe/UserUnsubscribe";
import UserVouchers from "components/customer_lookup/user/user_vouchers/UserVouchers";
import Button from "components/forms/button/Button";
import Modal from "components/modals/Modal";
import addNotification from "components/notifications/Notifications";
import VisibleWith from "components/visibleWith/VisibleWith";

const CisUserWrapper = ({ selectedMarket, reloadData, user = null }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showIssueRewardModal, setIssueRewardModal] = useState(false);
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);
  const [createReferralModal, setCreateReferralModal] = useState(false);

  const { editUserDetails, issueRewardReferrer, createReferral } = Permissions;

  const invertBoolean = (condition) => !condition;
  const toggleEditUserModal = () => setShowEditUserModal(invertBoolean);
  const toggleUnsubscribeModal = () => setUnsubscribeModal(invertBoolean);
  const toggleIssueRewardModal = () => setIssueRewardModal(invertBoolean);
  const toggleCreateReferralModal = () => setCreateReferralModal(invertBoolean);

  const backText = t("global.back");
  const cancelText = t("global.cancel");
  const editText = t("global.edit");
  const createReferralButtonText = t("userProfile.createReferralButtonText");
  const editDetailsText = t("userProfile.editDetailsText");
  const issueNewRewardText = t("userProfile.issueNewRewardText");
  const unsubscribeCloseModalText = t("userProfile.unsubscribeModal.closeModalText");
  const unsubscribeFromEmailsText = t("userProfile.unsubscribeFromEmailsText");

  const issueNewRewardModalTitle = t("userProfile.issueNewRewardModal.title");
  const issueNewRewardModalSubtitle = t("userProfile.issueNewRewardModal.subtitle");

  const successNotificationTitle = t("global.success");
  const successReferralCreationMessage = t("userProfile.successReferralCreationMessage");
  const timezoneOffset = selectedMarket.timezone_offset;

  const triggerEventAndReload = (fn) => () => {
    fn();
    reloadData();
  };

  const onSuccessfulUnsubscribe = triggerEventAndReload(toggleUnsubscribeModal);
  const onSuccessfulEdit = triggerEventAndReload(toggleEditUserModal);
  const onSuccessfulRewardCreation = triggerEventAndReload(toggleIssueRewardModal);

  const onReferralCreated = (friendId) => {
    addNotification.success(successNotificationTitle, successReferralCreationMessage);
    setCreateReferralModal(false);
    navigate(`/${selectedMarket.subdomain}/users/search/${friendId}`);
  };

  return (
    <div className="c-dashboard-user">
      <div className="c-dashboard-user-header">
        <div className="c-dashboard-user-header__action">
          <Button
            size="tiny"
            appearance="secondary"
            data-back-button
            onClick={() => window.history.back()}
            icon={<FaChevronLeft />}
          >
            {backText}
          </Button>
          {user.subscribed && (
            <Button
              size="tiny"
              appearance="secondary"
              data-unsubscribe-button
              onClick={toggleUnsubscribeModal}
            >
              {unsubscribeFromEmailsText}
            </Button>
          )}
        </div>
        <div className="c-dashboard-user-header__action">
          {user.isCustomer() && (
            <VisibleWith actions={[createReferral]}>
              <Button
                size="tiny"
                appearance="secondary"
                data-edit-user
                data-create-referral-button
                onClick={toggleCreateReferralModal}
              >
                {createReferralButtonText}
              </Button>
            </VisibleWith>
          )}
          {selectedMarket.hasAnyManuallyIssuableRewardTypesFor(user) && (
            <VisibleWith actions={[issueRewardReferrer]}>
              <Button
                size="tiny"
                appearance="secondary"
                data-edit-user
                data-issue-reward-button
                onClick={toggleIssueRewardModal}
              >
                {issueNewRewardText}
              </Button>
            </VisibleWith>
          )}
          <VisibleWith actions={[editUserDetails]}>
            <Button
              size="tiny"
              appearance="secondary"
              data-edit-user
              data-edit-user-button
              onClick={toggleEditUserModal}
            >
              {editText}
            </Button>
          </VisibleWith>
        </div>
      </div>

      <Modal
        closeModal={toggleEditUserModal}
        closeModalText={cancelText}
        openModal={showEditUserModal}
        title={editDetailsText}
        id="edit-details"
      >
        <UserEdit user={user} selectedMarket={selectedMarket} onSuccessfulEdit={onSuccessfulEdit} />
      </Modal>

      <Modal
        closeModal={toggleUnsubscribeModal}
        closeModalText={unsubscribeCloseModalText}
        openModal={unsubscribeModal}
        title={t("userProfile.unsubscribeModal.title", {
          fullName: fullName(user.firstName, user.lastName),
        })}
      >
        <UserUnsubscribe
          user={user}
          selectedMarket={selectedMarket}
          onSuccessfulUnsubscribe={onSuccessfulUnsubscribe}
        />
      </Modal>

      <Modal
        closeModal={toggleIssueRewardModal}
        closeModalText={cancelText}
        openModal={showIssueRewardModal}
        title={issueNewRewardModalTitle}
        subTitle={issueNewRewardModalSubtitle}
        id="issue-reward"
      >
        <IssueReward
          selectedMarket={selectedMarket}
          onSuccessfulRewardCreation={onSuccessfulRewardCreation}
          user={user}
        />
      </Modal>

      <CreateReferral
        selectedMarket={selectedMarket}
        onReferralCreated={onReferralCreated}
        referrerEmail={user.email}
        campaignParticipations={user.campaignParticipations}
        openModal={createReferralModal}
        closeModal={toggleCreateReferralModal}
      />

      <UserProfile user={user} timezoneOffset={timezoneOffset} />

      <CisUser user={user} isCustomer={user.isCustomer()} selectedMarket={selectedMarket} />

      {user.campaignReferrals.length > 0 && (
        <CisUserCampaigns user={user} selectedMarket={selectedMarket} reloadView={reloadData} />
      )}

      {user.isExternalUser() && (
        <>
          <UserVouchers vouchers={user.vouchers} />
          <UserOrders orders={user.orders} timezoneOffset={timezoneOffset} />
        </>
      )}
    </div>
  );
};

CisUserWrapper.propTypes = {
  selectedMarket: instanceOf(Market).isRequired,
  user: oneOfType([instanceOf(Referrer), instanceOf(Friend)]),
  reloadData: func.isRequired,
};

export default CisUserWrapper;
