import React, { useEffect, useRef, useState } from "react";

import { Download } from "assets/images/CustomIcons";
import FileSaver from "file-saver";
import i18next from "i18next";
import { DateTime } from "luxon";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";

import Button from "components/forms/button/Button";
import addNotification from "components/notifications/Notifications";
import CampaignSelector from "components/reports/campaign_selector/CampaignSelector";
import DateRangeSelected from "components/reports/date_picker/date_range_selected/DateRangeSelected";
import Tooltip from "components/tooltip/Tooltip";

import "./top_bar.scss";

const TopBar = ({
  selectedMarket,
  selectedCampaigns,
  selectedStartDate,
  selectedEndDate,
  campaignFilter,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  let downloadRetriesRef = useRef(50);
  let intervalRef = useRef(null);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearIntervalAsync(intervalRef.current);
      }
    };
  }, []);

  const resetDownloadInterval = () => {
    clearIntervalAsync(intervalRef.current);
    downloadRetriesRef.current = 50;
  };

  const toggleDownloadingState = (nowLoading, downloadError) => {
    setIsLoading(nowLoading);
    if (downloadError) {
      addNotification.warning(
        i18next.t("reporting.dashboard.topbar.downloadErrorTitle"),
        i18next.t("reporting.dashboard.topbar.downloadErrorContent")
      );
    }
  };

  const buildAndDownloadReport = async () => {
    const selectedCampaignIds = selectedCampaigns.map((c) => c.id);
    try {
      const builtReport = await selectedMarket.biReports.build(
        selectedCampaignIds,
        selectedStartDate,
        selectedEndDate
      );

      if (builtReport.ready) {
        const downloadedReport = await selectedMarket.biReports.download(builtReport.id);

        const startDateFormatted = DateTime.fromSeconds(selectedStartDate)
          .setZone("UTC" + selectedMarket.timezone_offset)
          .toFormat("yyyy-LL-dd");
        const endDateFormatted = DateTime.fromSeconds(selectedEndDate)
          .setZone("UTC" + selectedMarket.timezone_offset)
          .toFormat("yyyy-LL-dd");

        FileSaver.saveAs(
          downloadedReport.file,
          `buyapowa-report-${startDateFormatted}-to-${endDateFormatted}.xlsx`
        );

        resetDownloadInterval();
        toggleDownloadingState(false, false);
        return;
      }
    } catch (e) {
      toggleDownloadingState(false, true);
      resetDownloadInterval();
    }

    downloadRetriesRef.current--;
    if (downloadRetriesRef.current === 0) {
      toggleDownloadingState(false, true);
      resetDownloadInterval();
    }
  };

  const downloadSelectedReport = () => {
    toggleDownloadingState(true, false);
    intervalRef.current = setIntervalAsync(buildAndDownloadReport, 1000);
  };

  const startDate = DateTime.fromSeconds(selectedStartDate).setZone(
    "UTC" + selectedMarket.timezone_offset
  );
  const endDate = DateTime.fromSeconds(selectedEndDate).setZone(
    "UTC" + selectedMarket.timezone_offset
  );
  const statsStartingDate = selectedMarket.minStatsStartDate(selectedCampaigns);

  const shortFormat = endDate.diff(startDate, "years").toObject().years > 1 ? "LLL y" : "LLL";
  const dateRangeString = `${startDate.toFormat(shortFormat)} to ${endDate.toFormat(shortFormat)}`;

  return (
    <div className="top-bar">
      <div className="top-bar__actions">
        <div className="u-inline-3">
          <DateRangeSelected
            timezoneOffset={selectedMarket.timezone_offset}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            statsStartingDate={statsStartingDate}
          />
        </div>
        {selectedMarket.visibleCampaigns().length > 1 && (
          <CampaignSelector
            selectedMarket={selectedMarket}
            selectedCampaigns={selectedCampaigns}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            campaignFilter={campaignFilter}
          />
        )}
      </div>
      <div className="top-bar__actions">
        <Tooltip
          content={`${i18next.t("reporting.dashboard.topbar.tooltipContent")} (${dateRangeString})`}
        >
          <Button
            appearance="primary"
            size="small"
            icon={<Download />}
            loading={isLoading}
            onClick={downloadSelectedReport}
          >
            {isLoading
              ? i18next.t("reporting.dashboard.topbar.buttonDownloadReportLoadingText")
              : i18next.t("reporting.dashboard.topbar.buttonDownloadReportText")}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default TopBar;
