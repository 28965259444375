import React, { useEffect, useState } from "react";

import { Card, Switch } from "bp-ui";
import { t } from "i18next";
import { FaCirclePlay } from "react-icons/fa6";

export const NewUserToggleBanner = ({ currentUiSettingState, onChange }) => {
  const localValue = localStorage.getItem("portalReferrerNewUi");
  const [newUi, setNewUi] = useState(localValue ? localValue === "true" : currentUiSettingState);

  useEffect(() => {
    // write to local storage
    localStorage.setItem("portalReferrerNewUi", JSON.stringify(newUi));
    onChange && onChange(newUi);
  }, [newUi]);

  return (
    <Card.HeroRow shadow={false} className="text-white items-center font-normal">
      <Switch
        label={t("referrerProfile.newUiBanner.prompt")}
        value={newUi}
        onChange={() => {}}
        onClick={(e) => {
          e.preventDefault();
          setNewUi(!newUi);
        }}
        labelPosition="right"
      />
      <span className="border-0 border-solid !border-r border-white my-0.5 mx-3">&nbsp;</span>
      <FaCirclePlay className="mr-2" />
      <a
        target="_blank"
        href={t("referrerProfile.newUiBanner.demoLink")}
        className="text-white underline"
      >
        {t("referrerProfile.newUiBanner.demoLinkLabel")}
      </a>
    </Card.HeroRow>
  );
};
