import { useEffect, useState } from "react";

import "@/Base.css";
import { Button, Card, Modal, Permissions } from "bp-ui";
import { t } from "i18next";
import { FaChevronLeft, FaPlus, FaRegWindowMaximize } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import addNotification from "components/notifications/Notifications";
import Spinner from "components/spinner/Spinner";
import VisibleWith from "components/visibleWith/VisibleWith";

import { EditUser } from "./EditUser";
import { IssueReward } from "./IssueReward";
import { ReferralList } from "./ReferralList";
import { UserCard } from "./UserCard";
import CreateReferral from "./create_referral/CreateReferral.jsx";

export const User = ({ selectedMarket, reloadData, user }) => {
  const { createReferral, issueRewardReferrer } = Permissions;
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showIssueRewardModal, setShowIssueRewardModal] = useState(false);
  const [showCreateReferralModal, setShowCreateReferralModal] = useState(false);

  const [timelineData, setTimelineData] = useState([]);

  const [loadingError, setLoadingError] = useState(false);

  const navigate = useNavigate();

  const campaigns = (user.campaign ? [user.campaign] : user.campaigns) || [];

  useEffect(() => {
    const getData = async () => {
      if (user) {
        try {
          const timelineData = await selectedMarket.users.getTimeline(user.userId);

          setTimelineData(timelineData);
        } catch {
          // ignore for now
        }
      }
    };

    getData();
  }, [user]);

  if (!user) {
    return (
      <Spinner loadingText={t("userProfile.loadingText")} showSpinner={!user && !loadingError} />
    );
  }

  const onReferralCreated = (friendId) => {
    addNotification.success(t("global.success"), t("userProfile.successReferralCreationMessage"));
    setShowCreateReferralModal(false);
    navigate(`/${selectedMarket.subdomain}/users/search/${friendId}`);
  };

  const timezoneOffset = selectedMarket.timezone_offset;

  return (
    <div className="w-wide pt-5 flex flex-col gap-5 mx-auto mb-12">
      <div className="flex gap-2 text-dark-blue">
        <Button
          className="flex-0 mr-auto"
          secondary={true}
          onClick={() => window.history.back()}
          icon={FaChevronLeft}
        >
          {t("global.back")}
        </Button>

        {selectedMarket.hasAnyManuallyIssuableRewardTypesFor(user) && (
          <VisibleWith actions={[issueRewardReferrer]}>
            <Button
              className="flex-0"
              secondary={true}
              icon={FaRegWindowMaximize}
              onClick={() => setShowIssueRewardModal(true)}
            >
              {t("referrerProfile.issueNewRewardText")}
            </Button>
          </VisibleWith>
        )}
        {user.isCustomer() && (
          <VisibleWith actions={[createReferral]}>
            <Button
              className="flex-0"
              secondary={true}
              icon={FaPlus}
              onClick={() => setShowCreateReferralModal(true)}
            >
              {t("referrerProfile.createReferralButtonText")}
            </Button>
          </VisibleWith>
        )}
      </div>

      {loadingError ? (
        <div className="w-wide pt-5 flex flex-col gap-5 mx-auto mb-12">
          <Card.Card>
            <div className="mb-8">{t("userProfile.errorLoadingText")}</div>
          </Card.Card>
        </div>
      ) : (
        <>
          <h1 className="text-3xl font-normal mb-0">{t("referrerProfile.title")}</h1>

          <UserCard
            user={user}
            selectedMarket={selectedMarket}
            editDetails={() => setShowEditUserModal(true)}
            timezoneOffset={timezoneOffset}
            reloadData={reloadData}
            timelineData={timelineData}
          />

          <ReferralList
            referrals={user.referrals}
            fulfilments={user.fulfilments}
            user={user}
            timezoneOffset={timezoneOffset}
            timelineData={timelineData}
            selectedMarket={selectedMarket}
            reloadData={reloadData}
          />

          <Modal
            onClose={() => setShowEditUserModal(false)}
            open={showEditUserModal}
            title={t("userProfile.editDetailsText")}
            className="!max-w-1/3"
          >
            <EditUser
              user={user}
              selectedMarket={selectedMarket}
              onSuccessfulEdit={() => {
                setShowEditUserModal(false);
                reloadData && reloadData();
              }}
              onCancel={() => setShowEditUserModal(false)}
            />
          </Modal>

          <Modal
            onClose={() => setShowIssueRewardModal(false)}
            open={showIssueRewardModal}
            title={t("userProfile.issueNewRewardModal.title")}
            subTitle={t("userProfile.issueNewRewardModal.subtitle")}
            className="!max-w-1/3"
          >
            <IssueReward
              selectedMarket={selectedMarket}
              onSuccessfulRewardCreation={() => {
                setShowIssueRewardModal(false);
                reloadData && reloadData();
              }}
              user={user}
              onCancel={() => setShowIssueRewardModal(false)}
            />
          </Modal>

          <CreateReferral
            open={showCreateReferralModal}
            onClose={() => setShowCreateReferralModal(false)}
            onReferralCreated={onReferralCreated}
            referrerEmail={user.email}
            selectedMarket={selectedMarket}
            campaignParticipation={campaigns?.map((m) => ({
              campaign: m,
              joinedAt: m.joinedAt,
              shareUrl: m.shareUrl,
            }))}
          />
        </>
      )}
    </div>
  );
};
