import React, { useState } from "react";

import { Timeline } from "bp-ui/src/components/Card";
import { t } from "i18next";

import { FulfilmentActionsForm } from "components/customer_lookup/new_user/FulfilmentActionsForm/FulfilmentActionsForm";
import { FulfilmentStatus } from "components/customer_lookup/new_user/FulfilmentStatus/FulfilmentStatus";

import {
  emptyTimelinePlaceholderEvent,
  filterTimelineData,
  getReferralStatusIconFromValue,
  getReferralStatusTypeFromValue,
  sortTimelineData,
} from "../helpers";

export const FulfilmentTimeline = ({
  fulfilment,
  timelineData,
  userId,
  timezoneOffset,
  selectedMarket,
  reloadData,
}) => {
  const [timelineAdditions, setTimelineAdditions] = useState([]);

  const buildTimelineEvents = () => {
    const timeline = filterTimelineData(
      [...timelineData, ...timelineAdditions],
      (f) => f.fulfilmentId === fulfilment.id
    );

    const hideNumber = timeline.length === 0;

    if (timeline.length === 0) {
      timeline.push(emptyTimelinePlaceholderEvent());
    }

    timeline.push({
      hideNumber,
      status: getReferralStatusTypeFromValue(fulfilment.status.value),
      icon: getReferralStatusIconFromValue(fulfilment.status.value),
      title: t(`referrerProfile.referralTimeline.reward_${fulfilment.status.value}`),
      timestamp: fulfilment.status.updatedAt,
      content: (
        <div>
          {t(`referrerProfile.referralTimeline.reward_${fulfilment.status.value}_description`)}
          <FulfilmentStatus fulfilment={fulfilment} timezoneOffset={timezoneOffset} />
        </div>
      ),
    });

    return sortTimelineData(timeline);
  };

  return (
    <div className="flex flex-col w-full">
      <h2 className="text-xl mt-0 mb-8">
        {t("referrerProfile.referralInfoCard.rewardFulfilmentLog")}
      </h2>
      <Timeline events={buildTimelineEvents()} timezoneOffset={timezoneOffset} />

      <div className="pl-16 w-2/5">
        <FulfilmentActionsForm
          fulfilment={fulfilment}
          selectedMarket={selectedMarket}
          reloadData={reloadData}
          userId={userId}
          status={getReferralStatusTypeFromValue(fulfilment.status.value)}
          addToTimeline={(item) => setTimelineAdditions([...timelineAdditions, item])}
        />
      </div>
    </div>
  );
};
