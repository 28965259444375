import React from "react";

import { useAuth } from "@/AuthWrapper";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

import "./sub_navigation.scss";

const SubNavigation = ({
  subdomain,
  availableLinks,
  isSecondary = false,
  cisMarket = false,
  isFloating = false,
}) => {
  const { currentAdmin } = useAuth();

  const authorisedLinks = currentAdmin.super
    ? availableLinks
    : availableLinks.filter((link) => currentAdmin.canPerformAnyOf(link.permissions));

  const visibleLinks = authorisedLinks.filter((f) => f.visible === undefined || f.visible);

  const subNavClasses = classnames("c-sub-navigation", {
    "c-sub-navigation--secondary": isSecondary,
    "c-sub-navigation--floating": isFloating,
  });

  const className = "c-sub-navigation-item__link";
  return (
    <ul className={subNavClasses}>
      {visibleLinks.map(
        (link) =>
          (!link.cisOnly || link.cisOnly === cisMarket) && (
            <li className="c-sub-navigation-item" key={link.path}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${className}  c-sub-navigation-item__link--selected` : className
                }
                to={`/${subdomain}${link.path}`}
              >
                {link.text}
              </NavLink>
            </li>
          )
      )}
    </ul>
  );
};

export default SubNavigation;
