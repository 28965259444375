import React from "react";

import { Statuses } from "bp-ui";
import { t } from "i18next";
import { DateTime } from "luxon";
import {
  FaCalendarXmark,
  FaCheck,
  FaCirclePause,
  FaHourglassStart,
  FaRotate,
  FaXmark,
} from "react-icons/fa6";

export const getReferralStatusTypeFromValue = (value) => {
  switch (value) {
    case "complete":
      return Statuses.SUCCESS;
    case "pending":
      return Statuses.OK;
    case "failed":
      return Statuses.ERROR;
    case "processing":
      return Statuses.BUSY;
    case "paused":
      return Statuses.DEFAULT;
    case "expired":
      return Statuses.WARNING;
    case "cancelled":
      return Statuses.ERROR;
    case "redeemed":
      return Statuses.SUCCESS;
    default:
      return Statuses.DEFAULT;
  }
};

export const getReferralStatusLabelFromValue = (value) => {
  switch (value) {
    case "complete":
      return t("referrerProfile.statuses.complete");
    case "pending":
      return t("referrerProfile.statuses.pending");
    case "failed":
      return t("referrerProfile.statuses.failed");
    case "processing":
      return t("referrerProfile.statuses.processing");
    case "paused":
      return t("referrerProfile.statuses.paused");
    case "expired":
      return t("referrerProfile.statuses.expired");
    case "cancelled":
      return t("referrerProfile.statuses.cancelled");
    case "redeemed":
      return t("referrerProfile.statuses.redeemed");
    default:
      return value;
  }
};

export const getReferralStatusIconFromValue = (value) => {
  switch (value) {
    case "complete":
      return FaCheck;
    case "pending":
      return FaHourglassStart;
    case "failed":
      return FaXmark;
    case "processing":
      return FaRotate;
    case "paused":
      return FaCirclePause;
    case "expired":
      return FaCalendarXmark;
    case "cancelled":
      return FaXmark;
    case "redeemed":
      return FaCheck;
    default:
      return FaCheck;
  }
};

export const filterTimelineData = (timelineData, filterFn) => {
  return timelineData.filter(filterFn).map((m) => ({
    title: t(`referrerProfile.referralTimeline.emailTitle_${m.emailType}_${m.emailEvent}`),
    timestamp: m.timestamp,
  }));
};

export const sortTimelineData = (timelineData) => {
  return timelineData.sort((a, b) =>
    DateTime.fromISO(a.timestamp) < DateTime.fromISO(b.timestamp) ? -1 : 1
  );
};

export const emptyTimelinePlaceholderEvent = () => {
  return {
    hideNumber: true,
    status: Statuses.DEFAULT,
    title: t("referrerProfile.referralTimeline.dataUnavailable"),
    content: (
      <div>
        <div>{t("referrerProfile.referralTimeline.dataUnavailableDescription")}</div>
        <div>{t("referrerProfile.referralTimeline.dataUnavailableSupportInfo")}</div>
      </div>
    ),
  };
};

export const getFulfilmentStatusMessage = (fulfilment) => {
  const { status: fulfilmentStatus } = fulfilment;
  const paymentProviderStatusExists = fulfilmentStatus.paymentProviderStatus != null;
  const paymentProviderErrorExists = fulfilmentStatus.paymentProviderError != null;

  const statusMessage = paymentProviderStatusExists
    ? t(`fulfilments.paymentProviderStatuses.${fulfilmentStatus.paymentProviderStatus}`)
    : fulfilmentStatus.failureReason;

  const paymentProviderErrors = () => {
    if (
      fulfilmentStatus.paymentProviderError &&
      fulfilmentStatus.paymentProviderError.indexOf(" ") >= 0
    ) {
      return fulfilmentStatus.paymentProviderError;
    }

    return t(`fulfilments.paymentProviderStatuses.${fulfilmentStatus.paymentProviderError}`);
  };

  return paymentProviderErrorExists
    ? `${statusMessage}\n\n${paymentProviderErrors()}`
    : statusMessage;
};
