import React from "react";

import { Card } from "bp-ui";
import { useTranslation } from "react-i18next";

export const FulfilmentValue = ({ fulfilment }) => {
  const { t } = useTranslation();

  const fulfilmentMonetaryValue = () => {
    if (fulfilment.issuedCents === 0) {
      return t("referrerProfile.referralInfoCard.rewardValueNotSet");
    }

    return `${(fulfilment.issuedCents / 100).toFixed(2)} ${fulfilment.issuedCurrency?.toUpperCase()}`;
  };

  const hasVoucherCode = !!fulfilment.voucherCode;

  const fulfilmentValueLabel = () => {
    if (hasVoucherCode) {
      return t("referrerProfile.referralInfoCard.rewardCode");
    } else {
      return t("referrerProfile.referralInfoCard.rewardValue");
    }
  };

  const fulfilmentValue = () => {
    if (hasVoucherCode) {
      return fulfilment.voucherCode;
    } else {
      return fulfilmentMonetaryValue();
    }
  };

  return (
    <>
      <Card.Detail label={fulfilmentValueLabel()}>{fulfilmentValue()}</Card.Detail>
    </>
  );
};
