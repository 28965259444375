import React from "react";

import { t } from "i18next";

import { IneligibleReferralInfoCard } from "./ReferralInfoCards/IneligibleReferralInfoCard";
import { ManualFulfilmentInfoCard } from "./ReferralInfoCards/ManualFulfilmentInfoCard";
import { ReferralInfoCard } from "./ReferralInfoCards/ReferralInfoCard";
import { ReferralInfoWithReferrerCard } from "./ReferralInfoCards/ReferralInfoWithReferrerCard";
import { RewardPayoutInfoCard } from "./ReferralInfoCards/RewardPayoutInfoCard";

export const ReferralList = ({
  referrals,
  fulfilments,
  user,
  timelineData,
  timezoneOffset,
  selectedMarket,
  reloadData,
}) => {
  const infoCardsMap = {
    referrer_customer: ReferralInfoWithReferrerCard,
    referrer_external_user: ReferralInfoCard,
    referrer_partner: ReferralInfoWithReferrerCard,
    reward_customer: ReferralInfoWithReferrerCard,
    reward_external_user: RewardPayoutInfoCard,
    reward_partner: ReferralInfoWithReferrerCard,
    partner_customer: ReferralInfoWithReferrerCard,
    partner_external_user: ReferralInfoWithReferrerCard,
    partner_partner: ReferralInfoWithReferrerCard,
  };

  const groupedReferrals = referrals.reduce((acc, referral) => {
    const programType = referral.campaign.programType;
    if (!acc[programType]) {
      acc[programType] = [];
    }
    acc[programType].push(referral);
    return acc;
  }, {});

  const manualFulfilments = user.fulfilments.filter((f) => !f.referralId);

  return (
    <>
      {Object.keys(groupedReferrals).reduce((acc, programType) => {
        acc.push(
          <div key={programType} className="flex flex-col gap-5">
            <h2 className="text-3xl font-normal mb-0">
              {t(`referrerProfile.${programType}SectionTitle`)}
            </h2>
            {groupedReferrals[programType].map((referral) => {
              const filteredFulfilments = fulfilments.filter((f) => f.referralId === referral.id);
              const ReferralInfoCardType = infoCardsMap[`${programType}_${user.userType}`];

              return (
                <ReferralInfoCardType
                  key={referral.id}
                  referral={referral}
                  fulfilments={filteredFulfilments}
                  timezoneOffset={timezoneOffset}
                  user={user}
                  selectedMarket={selectedMarket}
                  reloadData={reloadData}
                  timelineData={timelineData}
                />
              );
            })}
          </div>
        );
        return acc;
      }, [])}

      {user.ineligibleReferrals && user.ineligibleReferrals.length > 0 ? (
        <>
          {user.ineligibleReferrals.map((ineligibleReferral) => (
            <IneligibleReferralInfoCard
              key={ineligibleReferral.id}
              ineligibleReferral={ineligibleReferral}
              timezoneOffset={timezoneOffset}
            />
          ))}
        </>
      ) : null}

      {manualFulfilments.length > 0 ? (
        <>
          <h2 className="text-3xl font-normal mb-0">{t(`referrerProfile.manualSectionTitle`)}</h2>
          {manualFulfilments.map((fulfilment) => (
            <ManualFulfilmentInfoCard
              key={fulfilment.id}
              selectedMarket={selectedMarket}
              fulfilment={fulfilment}
              timezoneOffset={timezoneOffset}
              user={user}
              timelineData={timelineData}
            />
          ))}
        </>
      ) : null}
    </>
  );
};
