import React from "react";

import { any, arrayOf, bool, shape, string } from "prop-types";
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";

import Button from "components/forms/button/Button";

const DownloadToCsv = ({
  headers,
  data,
  disabled = false,
  buttonCopy = "Download Report (CSV)",
  filename = "download.csv",
}) => {
  if (disabled) {
    return (
      <Button
        appearance="primary"
        size="small"
        buttonElement="link"
        disabled
        icon={<FiDownload />}
        href="#"
        onClick={(e) => e.preventDefault()}
        role="button"
      >
        {buttonCopy}
      </Button>
    );
  }

  return (
    <CSVLink
      className="c-button c-button--primary c-button--small"
      data={data}
      headers={headers}
      filename={filename}
      target="_blank"
      role="button"
    >
      <>
        <i className="c-button__icon">
          <FiDownload />
        </i>
        <span className="button__content">{buttonCopy}</span>
      </>
    </CSVLink>
  );
};

DownloadToCsv.propTypes = {
  headers: arrayOf(
    shape({
      label: string,
      key: string,
    })
  ).isRequired,

  data: arrayOf(any).isRequired,
  buttonCopy: string.isRequired,
  disabled: bool,
  filename: string,
};

export default DownloadToCsv;
