import React from "react";

import { fullName } from "helpers/Helpers";
import Market from "models/Market";
import SearchResult from "models/SearchResult";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import UserTypeIcon from "components/customer_lookup/icons/user_type/UserType";
import ReferralCount from "components/customer_lookup/referral_count/ReferralCount";
import UserType from "components/customer_lookup/row_atoms/user_type/UserType";
import Placeholder from "components/placeholder/Placeholder";

import "./user_result.scss";

const UserResult = ({ selectedMarket, searchResult }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${selectedMarket.subdomain}/users/search/${searchResult.id}`);
  };

  return (
    <tr className="single-result clickable" key={searchResult.id} onClick={handleClick}>
      <td>
        <span className="full-name">
          <Placeholder value={fullName(searchResult.firstName, searchResult.lastName)} />
        </span>
        <br />
        <Placeholder value={searchResult.email} />
      </td>
      <td>
        {(searchResult.customFields &&
          searchResult.customFields.length > 0 &&
          searchResult.customFields.map((customField) => (
            <div className="customfield-wrapper" key={`${customField.label}${customField.value}`}>
              <span>{customField.label}: </span>
              <span>{customField.value}</span>
            </div>
          ))) ||
          "-"}
      </td>
      <td>
        <UserType
          icon={<UserTypeIcon type={searchResult.type} />}
          descriptor={searchResult.displayType()}
        />
      </td>
      <td>
        <ReferralCount
          cancelledReferrals={searchResult.totalCancelledReferrals || 0}
          completedReferrals={searchResult.totalCompletedReferrals || 0}
          pendingReferrals={searchResult.totalPendingReferrals || 0}
          totalReferrals={searchResult.totalReferrals || 0}
        />
      </td>
    </tr>
  );
};

UserResult.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  searchResult: PropTypes.instanceOf(SearchResult).isRequired,
};

export default UserResult;
