import React from "react";

import { Timeline } from "bp-ui/src/components/Card";
import { t } from "i18next";

import { filterTimelineData, sortTimelineData } from "../helpers";

export const CampaignTimeline = ({ timelineData, campaign }) => {
  const buildTimelineEvents = () => {
    const filteredTimelineData = filterTimelineData(
      timelineData,
      (f) => !f.fulfilmentId && !f.referralId && f.campaignId === campaign.id
    );
    return sortTimelineData(filteredTimelineData);
  };
  const timelineEvents = buildTimelineEvents();

  return (
    <>
      {timelineEvents?.length > 0 && (
        <>
          <div className="w-full border-0 border-solid border-b border-grey h-px" />
          <div className="flex flex-col">
            <h2 className="text-xl mt-0 mb-8">
              {t("referrerProfile.referralTimeline.campaignEmailLogTitle")}
            </h2>
            <Timeline events={timelineEvents} />
          </div>
        </>
      )}
    </>
  );
};
