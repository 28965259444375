import React from "react";

import { Card } from "bp-ui";
import { useTranslation } from "react-i18next";
import { FaMoneyCheckDollar, FaRegCalendar } from "react-icons/fa6";

import FormattedDate from "components/formatted_date/FormattedDate";

import { ReferralStatus } from "./ReferralStatus";

export const IneligibleReferralInfoCard = ({ timezoneOffset, ineligibleReferral }) => {
  const { t } = useTranslation();
  const ineligibilityReason = t(
    `referrals.ineligibilityReasons.${ineligibleReferral.referralIneligibilityReason}`
  );

  return (
    <Card.Card className="flex-auto flex flex-col text-sm !pt-0 pb-2">
      <Card.Row border={false}>
        <div className="flex gap-6 mb-2">
          <div className="flex flex-none w-2/6 flex-row">
            <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>
            <FaRegCalendar className="text-2xl mr-3 mt-3" />
            <Card.Detail border={false} label={t("referrerProfile.referralInfoCard.referralDate")}>
              <FormattedDate
                timezoneOffset={timezoneOffset}
                type="numeral_short_with_time"
                timestamp={ineligibleReferral.createdAt}
              />
            </Card.Detail>
          </div>
          <Card.Detail
            className="flex-2 mr-9"
            label={t("referrerProfile.referralInfoCard.orderNumber")}
          >
            {ineligibleReferral.clientOrderId}
          </Card.Detail>
          <Card.Detail label={t("referrerProfile.referralInfoCard.referralStatus")}>
            <ReferralStatus status="failed" tooltip={ineligibilityReason} />
          </Card.Detail>
        </div>

        {ineligibleReferral.voucherCode && ineligibleReferral.voucherCode != "" ? (
          <div className="flex gap-6">
            <div className="flex flex-none w-2/6 flex-row">
              <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>

              <FaMoneyCheckDollar className="text-2xl mr-3 mt-3" />

              <Card.Detail border={false} label={t("vouchers.tableHeaders.voucher")}>
                {ineligibleReferral.voucherCode}
              </Card.Detail>
            </div>
          </div>
        ) : null}
      </Card.Row>
    </Card.Card>
  );
};
