import React, { useCallback, useEffect, useState } from "react";

import { areRequestsCancelled, cancelRequests } from "@/axiosInterceptor";
import Market from "models/Market";
import Partner from "models/Partner";
import PropTypes, { instanceOf } from "prop-types";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";

import UserFulfilments from "components/customer_lookup/user/user_fulfilments/UserFulfilments";
import UserReferrals from "components/customer_lookup/user/user_referrals/UserReferrals";
import ErrorLoader from "components/error_loader/ErrorLoader";
import Button from "components/forms/button/Button";
import Campaigns from "components/partner_management/partner_profile/campaigns/Campaigns";
import "components/partner_management/partner_profile/partner_profile.scss";
import Performance from "components/partner_management/partner_profile/performance/Performance";
import Profile from "components/partner_management/partner_profile/profile/Profile";
import Rewards from "components/partner_management/partner_profile/rewards/Rewards";
import Spinner from "components/spinner/Spinner";

const PartnerProfile = ({ selectedMarket, timezoneOffset }) => {
  const { partnerId } = useParams();
  const { t } = useTranslation();

  const [loadingError, setLoadingError] = useState(false);
  const [partner, setPartner] = useState(null);

  const findPartner = useCallback(async () => {
    try {
      if (!partnerId) {
        return;
      }

      const loadedPartner = await selectedMarket.users.getPartner(partnerId);

      const newPartner = new Partner(loadedPartner.data.payload);
      setPartner(newPartner);
    } catch (e) {
      if (!areRequestsCancelled(e)) {
        setLoadingError(true);
      }
    }
  }, [selectedMarket, partnerId]);

  // To avoid this error:
  // It looks like you wrote useEffect(async () => ...) or returned a Promise.
  // Instead, write the async function inside your effect and call it immediately.
  // Moved definition out of the useEffect to pass into a component as well.
  const reloadPartner = useCallback(async () => {
    await findPartner();
  }, [findPartner]);

  useEffect(() => {
    reloadPartner();

    return () => cancelRequests();
  }, [reloadPartner]);

  return (
    <>
      <Spinner
        loadingText={t("partnerProfile.loadingText")}
        showSpinner={!partner && !loadingError}
      />
      <ErrorLoader
        errorText={t("partnerProfile.errorLoadingText")}
        showError={loadingError}
        hasCardWrapper
      />

      <div className="c-partner-profile__outer-buttons">
        <Link to={`/${selectedMarket.subdomain}/users/partners`}>
          <Button
            className="c-partner-profile__transparent-button"
            appearance="secondary"
            size="medium"
            icon={<FaChevronLeft />}
          >
            {t("global.back")}
          </Button>
        </Link>
      </div>

      {partner && (
        <>
          <Profile
            market={selectedMarket}
            partnerId={partnerId}
            partner={partner}
            reloadPartner={reloadPartner}
          />
          <Rewards
            market={selectedMarket}
            partnerId={partnerId}
            campaignUrls={partner.campaignUrls}
          />
          <Campaigns timezoneOffset={timezoneOffset} campaignUrls={partner.campaignUrls} />
          {partner.referrals.length > 0 && (
            <UserReferrals
              referrals={partner.referrals}
              timezoneOffset={timezoneOffset}
              selectedMarket={selectedMarket}
              reloadView={reloadPartner}
            />
          )}
          {partner.fulfilments.length > 0 && (
            <UserFulfilments
              fulfilments={partner.fulfilments}
              userId={partnerId}
              selectedMarket={selectedMarket}
              reloadView={reloadPartner}
            />
          )}
        </>
      )}

      <Performance selectedMarket={selectedMarket} partnerId={partnerId} />
    </>
  );
};

PartnerProfile.propTypes = {
  selectedMarket: instanceOf(Market).isRequired,
  timezoneOffset: PropTypes.string.isRequired,
};

export default PartnerProfile;
