import React, { useCallback, useEffect, useState } from "react";

import { areRequestsCancelled, cancelRequests } from "@/axiosInterceptor";
import { useFlags } from "launchdarkly-react-client-sdk";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CisUserWrapper from "components/customer_lookup/cis_user/cis_user_wrapper/CisUserWrapper";
import { NewUserToggleBanner } from "components/customer_lookup/new_user/NewUserToggleBanner";
import { User as NewUser } from "components/customer_lookup/new_user/User";
import UserWrapper from "components/customer_lookup/user/user_wrapper/UserWrapper";
import ErrorLoader from "components/error_loader/ErrorLoader";
import Spinner from "components/spinner/Spinner";

import "./user.scss";

export const User = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [userData, setUser] = useState(null);
  const [loadingError, setLoadingError] = useState(false);

  const {
    portalReferrerDnaNewUi: ldShowNewUserWrapper,
    portalReferrerDnaNewUiUserToggle: showNewUserToggle,
  } = useFlags();

  const [showNewUserWrapper, setShowNewUserWrapper] = useState(ldShowNewUserWrapper);

  const findUser = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      let loadedUser = await selectedMarket.users.find(id);

      if (!loadedUser.isCIS() && showNewUserWrapper) {
        loadedUser = await selectedMarket.users.find(id, showNewUserWrapper);
      }

      setUser(loadedUser);
    } catch (e) {
      if (!areRequestsCancelled(e)) {
        setLoadingError(true);
      }
    }
  }, [id, selectedMarket?.users, showNewUserWrapper]);

  const requestData = useCallback(async () => {
    await findUser();
  }, [findUser]);

  useEffect(() => {
    requestData();
    return () => cancelRequests();
  }, [requestData, showNewUserWrapper]);

  const loadingText = t("userProfile.loadingText");
  const errorLoadingText = t("userProfile.errorLoadingText");

  const showCisUserWrapper = userData && userData.isCIS();
  const showUserWrapper = userData && !userData.isCIS();

  return (
    <>
      <Spinner loadingText={loadingText} showSpinner={!userData && !loadingError} />
      <ErrorLoader errorText={errorLoadingText} showError={loadingError} hasCardWrapper />
      {showNewUserToggle && (
        <NewUserToggleBanner
          currentUiSettingState={showNewUserWrapper}
          onChange={(value) => {
            setUser(null);
            setShowNewUserWrapper(value);
          }}
        />
      )}
      {showCisUserWrapper && (
        <CisUserWrapper user={userData} selectedMarket={selectedMarket} reloadData={requestData} />
      )}
      {showUserWrapper && !showNewUserWrapper && (
        <UserWrapper user={userData} selectedMarket={selectedMarket} reloadData={requestData} />
      )}
      {showUserWrapper && showNewUserWrapper && (
        <NewUser selectedMarket={selectedMarket} reloadData={requestData} user={userData} />
      )}
    </>
  );
};

User.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default User;
