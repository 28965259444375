import React from "react";

import { FaCheck } from "react-icons/fa6";

import { FormattedDate } from "../../FormattedDate/FormattedDate.jsx";
import { StatusColours, StatusIcons, Statuses } from "../../enums";
import { Card } from "../Card";

export const Timeline = ({ events, timezoneOffset }) => (
  <div>
    {events?.map((event, index) => {
      const status = event.status || Statuses.SUCCESS;
      const StatusIcon = event.icon || StatusIcons[status] || FaCheck;
      const statusColour = StatusColours[status];

      return (
        <div className="flex flex-row pb-10" key={index}>
          <div className="mr-10 flex relative">
            <div
              className={`rounded-full bg-${statusColour} aspect-square w-8 h-8 p-1.5`}
            >
              <StatusIcon className="text-white w-full h-full text-center" />
            </div>

            {index < events.length - 1 && (
              <div
                className={`flex-none mt-2 w-0.5 h-full-2 absolute top-8 left-1/2 -translate-x-1/2 bg-${statusColour}`}
              />
            )}
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-dark-blue text-l font-bold">
              {event.hideNumber ? "" : `${index + 1}. `}
              {event.title}
            </div>
            {event.timestamp && (
              <div className="text-dark-blue text-l">
                <FormattedDate
                  timestamp={event.timestamp}
                  timezoneOffset={timezoneOffset}
                  type="numeral_short_with_time"
                />
              </div>
            )}
            {event.content}
          </div>
        </div>
      );
    })}
  </div>
);

export const Preview = () => {
  return (
    <Card>
      <Timeline
        events={[
          {
            title: "Event 1",
            timestamp: new Date().toISOString(),
            content: "Content for event 1",
          },
          {
            title: "Event 2",
            timestamp: new Date().toISOString(),
            content: "Content for event 2",
          },
        ]}
        timezoneOffset={0}
      />
    </Card>
  );
};
