import React from "react";

import { Card } from "bp-ui";
import { fullName } from "helpers/Helpers";
import { useTranslation } from "react-i18next";
import { FaBullhorn, FaUser } from "react-icons/fa6";
import { Link } from "react-router-dom";

import FormattedDate from "components/formatted_date/FormattedDate";

import { ReferralInfoDetails } from "./ReferralInfoDetails";

export const ReferralInfoWithReferrerCard = ({
  referral,
  fulfilments,
  user,
  timezoneOffset,
  selectedMarket,
  timelineData = [],
  reloadData,
}) => {
  const { t } = useTranslation();

  const [campaignDetailsCollapsed, setCampaignDetailsCollapsed] = React.useState(true);

  const linkedUser = referral.referrer || referral.friend;

  const linkedUserFullName = fullName(linkedUser.firstName, linkedUser.lastName);

  const fields = [
    [
      t("referrerProfile.referrerInfoCard.locale"),
      t(`languages.${linkedUser.locale ? linkedUser.locale : selectedMarket.defaultLocale}`),
    ],
    [
      t("referrerProfile.referrerInfoCard.dateRegistered"),
      <FormattedDate
        timestamp={linkedUser.joinedAt}
        timezoneOffset={timezoneOffset}
        type="numeral_short_with_time"
      />,
    ],
    ...(linkedUser.customFields || []).slice(0, 3).map((m) => [m.label, m.value]),
  ];

  return (
    <Card.Card className="flex-auto flex flex-col text-sm pb-2">
      <Card.Collapse
        open={!campaignDetailsCollapsed}
        detail={fields.map((field) => (
          <Card.Detail key={field[0]} border={false} label={field[0]} className="flex-none w-1/5">
            {field[1]}
          </Card.Detail>
        ))}
      >
        <div className="flex gap-6 mb-3">
          <div className="flex flex-none w-2/6 flex-row">
            <div className="rounded-full bg-dark-blue aspect-square w-14 h-14 p-3.5 mr-10">
              <FaUser className="text-white w-full h-full text-center" />
            </div>
            <div className="flex flex-auto flex-col">
              <div className="mb-1 font-bold">
                {t("referrerProfile.referralInfoCard.referralName")}
              </div>
              <div className="text-dark-blue font-bold break-all">
                <Link
                  className="cursor-pointer hover:text-dark-blue underline text-mid-blue text-xl"
                  to={`/${selectedMarket.subdomain}/users/search/${linkedUser.id}`}
                >
                  {linkedUserFullName}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-auto flex-col break-all">
            <div className="mb-1 font-bold">
              {t("referrerProfile.referralInfoCard.emailAddress")}
            </div>
            <div className="text-dark-blue text-xl font-bold">{linkedUser.email}</div>
          </div>
          <div className="flex flex-none justify-end items-end">
            <Card.CollapseButton
              onClick={() => setCampaignDetailsCollapsed(!campaignDetailsCollapsed)}
              collapsed={campaignDetailsCollapsed}
              expandSectionLabel={t("global.expandSection")}
              collapseSectionLabel={t("global.collapseSection")}
            />
          </div>
        </div>

        <div className="flex">
          <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>
          <span className="flex text-xl justify-center">
            <FaBullhorn className="text-2xl mr-2" />
            {referral.campaign.name}
          </span>
        </div>

        {campaignDetailsCollapsed ? (
          <div className="border-0 border-solid border-b border-grey h-px" />
        ) : null}
      </Card.Collapse>

      <ReferralInfoDetails
        fulfilments={fulfilments}
        timelineData={timelineData}
        timezoneOffset={timezoneOffset}
        referral={referral}
        user={user}
        selectedMarket={selectedMarket}
        reloadData={reloadData}
      />
    </Card.Card>
  );
};
