import React from "react";

import { DateTime } from "luxon";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

import FormattedDate from "components/formatted_date/FormattedDate";
import "components/reports/date_picker/date_range_selector/custom_range/calendars/calendar/calendar.scss";
import CalendarMonth from "components/reports/date_picker/date_range_selector/custom_range/calendars/calendar_month/CalendarMonth";

const monthIcon = (type) => (type === "first" ? <FiChevronLeft /> : <FiChevronRight />);
const yearIcon = (type) => (type === "first" ? <FiChevronsLeft /> : <FiChevronsRight />);
const headerClass = (type) => (type === "first" ? "calendar-header first" : "calendar-header last");

const Calendar = ({
  type,
  currentDate,
  monthAction,
  yearAction,
  updateNewDateRange,
  newStartDate,
  newEndDate,
  timezoneOffset,
}) => (
  <div className={`${type}-month`}>
    <div className={headerClass(type)}>
      {type === "first" && (
        <>
          <button aria-label={`${type}-year`} type="button" onClick={yearAction}>
            {yearIcon(type)}
          </button>
          <button aria-label={`${type}-month`} type="button" onClick={monthAction}>
            {monthIcon(type)}
          </button>
        </>
      )}
      <div className="calendar-label">
        <FormattedDate
          timestamp={Math.floor(DateTime.fromISO(currentDate).toSeconds())}
          timezoneOffset={timezoneOffset}
          type="short_without_day"
        />
      </div>
      {type === "last" && (
        <>
          <button aria-label={`${type}-month`} type="button" onClick={monthAction}>
            {monthIcon(type)}
          </button>
          <button aria-label={`${type}-year`} type="button" onClick={yearAction}>
            {yearIcon(type)}
          </button>
        </>
      )}
    </div>
    <CalendarMonth
      currentDate={currentDate}
      updateNewDateRange={updateNewDateRange}
      newStartDate={newStartDate}
      newEndDate={newEndDate}
    />
  </div>
);

export default Calendar;
