import React from "react";

import { useAuth } from "@/AuthWrapper";
import { Permissions } from "bp-ui";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";

import BulkActions from "components/bulk_actions/BulkActions";
import SearchWrapper from "components/customer_lookup/search/SearchWrapper";
import UserSearch from "components/customer_lookup/search/user_search/UserSearch";
import { User } from "components/customer_lookup/user/User";
import Navigation from "components/navigation/Navigation";
import Partners from "components/partner_management/Partners";
import PartnerProfile from "components/partner_management/partner_profile/PartnerProfile";
import SubNavigation from "components/sub_navigation/SubNavigation";

import "./users.scss";

const {
  bulkActionTransactions,
  bulkActionReferrals,
  bulkActionSignups,
  bulkActionCustomers,
  bulkActionForgetUsers,
  managePartners,
} = Permissions;

const Users = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const timezoneOffset = selectedMarket.timezone_offset;

  const bulkActionsPermissions = [
    bulkActionTransactions,
    bulkActionReferrals,
    bulkActionSignups,
    bulkActionCustomers,
    bulkActionForgetUsers,
  ];

  const availableLinks = [
    {
      text: t("users.navigation.partnerManagement"),
      path: "/users/partners",
      permissions: [managePartners],
    },
    {
      text: t("users.navigation.customerManagement"),
      path: "/users/search",
      permissions: [],
    },
    {
      text: t("users.navigation.bulkActions"),
      path: "/users/bulk-actions",
      permissions: bulkActionsPermissions,
    },
  ];

  return (
    <div className="c-users">
      <SearchWrapper>
        <Navigation selectedMarket={selectedMarket} />
        <SubNavigation subdomain={selectedMarket.subdomain} availableLinks={availableLinks} />
        <Routes>
          <Route path={"search"} element={<UserSearch selectedMarket={selectedMarket} />} />
          <Route path={"search/:id"} element={<User selectedMarket={selectedMarket} />} />
          <Route path={""} element={<Navigate to={"search"} />} />
          <Route path={"partners"} element={<Partners selectedMarket={selectedMarket} />} />
          <Route
            path={"partners/:partnerId"}
            element={
              <PartnerProfile selectedMarket={selectedMarket} timezoneOffset={timezoneOffset} />
            }
          />
          <Route
            path={"bulk-actions/*"}
            element={<BulkActions selectedMarket={selectedMarket} />}
          />
        </Routes>
      </SearchWrapper>
    </div>
  );
};

export default Users;
