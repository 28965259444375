import React, { useState } from "react";

import { Card, Link } from "bp-ui";
import { useTranslation } from "react-i18next";
import { FaBullhorn } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

import { UserCampaignDetails } from "components/customer_lookup/new_user/UserCampaignDetails/UserCampaignDetails";

export const FriendCampaign = ({
  selectedMarket,
  campaign,
  user,
  timezoneOffset,
  referrals,
  reloadData,
  timelineData,
}) => {
  const [sectionOpen, setSectionOpen] = useState(false);

  const { t } = useTranslation();
  const referrers = referrals?.filter((f) => f.campaign.id === campaign.id);

  return (
    <Card.Collapse
      key={campaign.name}
      open={sectionOpen}
      detail={
        <UserCampaignDetails
          selectedMarket={selectedMarket}
          campaign={campaign}
          user={user}
          timezoneOffset={timezoneOffset}
          reloadData={reloadData}
          timelineData={timelineData}
        />
      }
    >
      <div className="flex flex-row gap-6 my-8 items-start justify-between">
        <div className="flex flex-none w-2/6 flex-row">
          <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>
          <Card.Detail border={false} label={t("referrerProfile.referrerInfoCard.campaign")}>
            <div className="flex text-xl justify-center -mt-2">
              <FaBullhorn className="text-2xl mr-2" />
              {campaign.name}
            </div>
          </Card.Detail>
        </div>
        {referrers.length > 0 ? (
          <Card.Detail label={t("referrerProfile.referrerInfoCard.referredBy")}>
            {referrers.map((m) => (
              <Link
                className="underline text-dark-blue text-sm"
                key={m.id}
                to={`/${selectedMarket.subdomain}/users/search/${m.referrer.id}`}
                elementToUse={NavLink}
              >
                {`${m.referrer.firstName} ${m.referrer.lastName}`}
              </Link>
            ))}
          </Card.Detail>
        ) : null}
        <div className="flex flex-none self-center">
          <Card.CollapseButton
            onClick={() => setSectionOpen(!sectionOpen)}
            collapsed={!sectionOpen}
            expandSectionLabel={t("global.expandSection")}
            collapseSectionLabel={t("global.collapseSection")}
          />
        </div>
      </div>
    </Card.Collapse>
  );
};
