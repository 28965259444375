import React, { useRef } from "react";

import { useTranslation } from "react-i18next";
import { GoSearch } from "react-icons/go";
import MediaQuery from "react-responsive";

import Button from "components/forms/button/Button";
import TextInput from "components/forms/text_input/TextInput";

import "./search_bar.scss";

const SearchBar = ({
  action,
  handleChange,
  searchTerm,
  loading,
  resultsCount = 0,
  hasSearched = false,
}) => {
  const { t } = useTranslation();
  const searchText = t("global.search");
  const onSubmit = (event) => {
    event.preventDefault();
    action();
  };

  const onChange = (event) => handleChange(event.target.value);
  const ref = useRef();

  return (
    <form role="search" onSubmit={onSubmit} className="search-bar input-bar--filter">
      <TextInput
        labelText={searchText}
        placeholder={searchText}
        id="search"
        value={searchTerm}
        handleChange={onChange}
        required
        focus
        addInputStyling={false}
        hideLabel
        ref={ref}
      />
      {hasSearched && (
        <div className="user-results-count">
          {t("userSearch.searchResult", { count: resultsCount })}
        </div>
      )}
      <MediaQuery minWidth={1200}>
        <Button buttonElement="submit" appearance="tertiary" loading={loading}>
          {searchText}
        </Button>
      </MediaQuery>
      <MediaQuery maxWidth={1199}>
        <Button
          buttonElement="submit"
          appearance="tertiary"
          loading={loading}
          icon={<GoSearch />}
        />
      </MediaQuery>
    </form>
  );
};
export default SearchBar;
