import countryCodeObject from "../assets/countryCodes.json";

const passwordFormatError =
  "Le mot de passe que vous avez saisi n'est pas assez complexe, ajoutez des chiffres, des symboles ou plus de lettres pour le rendre plus complexe.";

export default {
  translation: {
    accountDetails: {
      accountCreationDate: "date de création du compte",
      accountIdentifier: "identifiant du compte",
      accountType: "type de compte",
      accountStatus: "statut du compte",
      eligibleForRewards: "éligible pour les récompenses",
      internalIdentifier: "identifiant interne",
      subAccountType: "type de sous-compte",
    },
    auth: {
      generic: "Impossible de se connecter pour le moment. Veuillez réessayer plus tard.",
      unauthorized_ip_address: "Accès refusé à partir de cette adresse IP.",
    },
    bulkActions: {
      successNotification: "Votre fichier a été téléchargé avec succès et sera traité",
      downloadCsvDescription:
        "Téléchargez notre modèle prêt à l'emploi pour vous assurer de nous fournir les données correctes",
      downloadCsvButtonText: "Télécharger le modèle CSV",
      notification: "Vous serez informé par e-mail du statut de votre import.",
      addCustomers: {
        fileImport: {
          buttonLabel: "Ajouter des clients",
          checkboxLabel: "Les emails sont des hashs MD5.",
        },
        header: "Ajouter des clients",
        history: {
          header: "Historique",
          tableHeaders: {
            customers: "Clients",
            existing: "Existants",
            new: "Nouveaux",
            numberOf: "Nombre de",
            processedRows: "Lignes Traitées",
            status: "Statut",
            uploadedAt: "Téléchargé À",
            uploadedBy: "Téléchargé Par",
          },
        },
        modal: {
          emailDescription:
            'L\'adresse email du parrain en texte brut (par ex. "john@doe.com") ou un hash MD5 d\'un adresse email en minuscule (par ex. "30123074929abe9168856af4f4bc904b").',
          openButtonText: "Quelles sont les en-têtes de colonne obligatoires?",
          title: "En-têtes de colonne obligatoires",
        },
        subtitle:
          "Téléchargez une liste d'adresses email de clients que la plateforme utilisera pour valider l'éligibilité des filleuls à obtenir un bon d'achat sur leur page. Les emails peuvent être soit en texte brut, soit des hash MD5 d'adresses email en minuscule. Vous pourriez avoir besoin de contacter votre Client Success Manager pour configurer cette fonctionnalité si vous l’utilisez pour la première fois.",
      },
      forgetUsers: {
        header: "Utilisateur Oubliés",
        history: {
          header: "Historique",
          tableHeaders: {
            forgottenUserCount: "Compte Utilisateur Oublié",
            status: "Statut",
            uploadedAt: "Téléchargé À",
            uploadedBy: "Téléchargé Par",
          },
        },
        fileImport: {
          buttonLabel: "Télécharger un fichier CSV",
        },
        modal: {
          emailDescription: 'L\'adresse email du parrain par ex. "john@doe.com"',
          openButtonText:
            "Quelles sont les en-têtes de colonne obligatoires pour le téléchargement d'un fichier CSV ?",
          title: "En-têtes de colonne obligatoires",
        },
        subtitle:
          "Vous pouvez télécharger une liste d'adresses email d'utilisateurs à supprimer de nos systèmes.Vous pouvez également saisir les adresses email de chaque utilisateur, chacune sur une nouvelle ligne",
        textImport: {
          buttonLabel: "Soumettre",
          placeholder: "Saisissez manuellement une liste d'emails, chacun sur une nouvelle ligne.",
          invalidWarning: "Les adresses emails suivantes sont invalides",
        },
      },
      navigation: {
        addCustomers: "Ajouter des clients",
        forgetUsers: "Oublier les utilisateurs",
        referralManagement: "Gestion des récompenses",
        signups: "Inscriptions",
        transactionTracking: "Suivi de la Transaction",
      },
      referralManagement: {
        history: {
          header: "Historique",
          tableHeaders: {
            status: "Statut",
            uploadedAt: "Téléversé À",
            uploadedBy: "Téléversé Par",
            skippedReferrals: "Ignorée",
            confirmedReferrals: "Confirmé",
            cancelledReferral: "Annulé",
            restoredReferrals: "Restauré",
          },
        },
        campaignListPlaceholder: {
          withResults: "Sélectionnez une campagne",
          noResults: "Aucun parrainage en attente dans aucune campagne",
        },
        exportButton: {
          loading: "Création en cours, veuillez patienter…",
          text: "Exporter le fichier CSV",
          errorSubmittedMessage:
            "Il y a une erreur dans le traitement du rapport, merci de reessayer.",
        },
        fileImport: {
          buttonLabel: "Importer le fichier CSV",
          checkboxLabel: "Valider les types de récompense?",
        },
        header: "Gestion des récompenses",
        referralCount: "{{count}} parrainages en attente",
        referralCount_one: "{{count}} parrainage en attente",
        stepFirstHeader: "Étape 1",
        stepFirstSubtitle:
          "Ce fichier CSV contient tous vos parrainages qui sont actuellement en attente. Les parrainages confirmés ou annulés auparavant n'apparaîtront pas ici.",
        stepSecondHeader: "Étape 2 - Modifier le statut",
        stepSecondDescription: {
          updateStatusColumn:
            "Modifiez dans le fichier CSV la valeur de la colonne <code>{{statusColumnHeader}}</code> en:",
          confirmedValueDescription: "pour le marquer comme validé",
          cancelledValueDescription: "pour le marquer comme annulé.",
          unmodifiedRowsNote:
            "N'éditez pas les enregistrements qui n'ont pas besoin d'être mis à jour.",
        },
        stepThirdHeader: "Étape 3 - Colonne facultatif",
        stepThirdDescription: {
          addRewardCategoryColumn: [
            "Ajouter une catégorie de récompense<br>",
            "Ajoutez une colonne <code>{{rewardCategoryColumnHeader}}</code> au fichier CSV, en la plaçant <strong>après</strong> la colonne <code>{{statusColumnHeader}}</code>.",
            "Toutes les lignes qui n'ont pas de catégorie de récompense peuvent être laissées vides pour cette colonne.",
          ],
          addClientDataColumns: [
            "Ajouter des données clients<br>",
            "<strong>Ajouter de colonne(s):</strong> Ajouter des colonne(s) de données clients au fichier CSV après <code>{{statusColumnHeader}}</code> et <code>{{rewardCategoryColumnHeader}}</code> (facultatif). Le titre de la colonne doit être au format <code>ClientData_{name}</code>, par exemple <code>ClientData_Customer ID</code> pour <code>Customer ID</code>.",
            "<strong>Mise à jour des données:</strong> Pour mettre à jour les données existantes, assurez-vous que les titres de vos nouvelles colonne(s) <code>Client Data</code> correspondent à ceux des colonnes existantes.",
            "<strong>Pas de données:</strong> Les lignes sans <code>Client Data</code> peuvent être laissées vides.",
            "<strong>Suppression:</strong> Il n'est pas possible de supprimer les <code>Client Data</code> en utilisant ce processus.",
          ],
        },
        stepFourthHeader: "Étape 4 - Télécharger",
        stepFourthDescription: {
          requiredColumns: "Le fichier CSV doit contenir les deux colonnes suivantes:",
          optionalColumns:
            "Le fichier CSV peut également contenir une troisième colonne facultative:",
          requiredColumnSeparator: "ou",
        },
        stepFifthHeader: "Étape 5 - Finaliser",
        stepFifthSubtitle:
          "Une fois le fichier reçu, le système traite les données et distribue les récompenses appropriées aux clients.",
        subtitle:
          "Tout client effectuant un parrainage confirmé sera marqué comme étant en attente, afin que vous puissiez vous assurer que la transaction est effective avant de distribuer une récompense.",
      },
      signups: {
        campaignSelectPlaceholder: "Sélectionner une campagne",
        campaignListPlaceholder: "Il n'y a pas de campagne pour ce marché",
        fileImport: {
          buttonLabel: "Télécharger",
          checkboxLabel:
            "Envoyez un email d'invitation aux utilisateurs qui ne se sont pas encore inscrits (n'envoyez des emails d'invitation que si les utilisateurs ont accepté de recevoir des emails marketing)",
        },
        header: "Inscriptions",
        history: {
          header: "Historique",
          tableHeaders: {
            campaign: "Campagne",
            status: "Statut",
            uploadedAt: "Téléchargez À",
            uploadedBy: "Téléchargez Par",
            successfulImports: "Importation(s) réussie(s)",
            failedImports: "Importation(s) échouée(s)",
          },
        },
        modal: {
          optional: {
            clientDataDescription:
              'Métadonnées supplémentaires spécifiées par le client et associées au parrain inscrit à la campagne par ex. "ClientData_customer_id", "ClientData_phone_number". Toujours facultatif.',
            customFieldDescription:
              "Si vous utilisez un seul champ personnalisé pour identifier un client, ce champ peut contenir un élément tel qu'un ID de membre ou un numéro de téléphone. Si l'utilisateur doit saisir cette valeur lors de l'inscription, alors elle est obligatoire dans ce fichier.",
            customFieldsDescription:
              'Si vous utilisez plusieurs champs personnalisés pour identifier un client, ces champs peuvent contenir un élément tel qu\'un ID de membre ou un numéro de téléphone, e.g. "CustomField_phone_number", "CustomField_membership_number". Si l\'utilisateur doit saisir ces valeurs lors de l\'inscription, alors elles sont obligatoires dans ce fichier.',
            header: "Configuration de la campagne en attente, facultative ou obligatoire",
            lastNameDescription: 'Le nom de la personne, par ex. "Smith". Toujours facultatif.',
            localeDescription:
              "La langue du client. Lorsqu'un client reçoit des courriels liés à la campagne de parrainage, ils s'affichent dans la langue indiquée ici. Cette option est facultative. Si aucun paramètre n'est fourni, le système utilisera le paramètre par défaut du marché",
          },
          required: {
            emailDescription: 'L\'adresse email de la personne, par ex. "john@doe.com".',
            firstNameDescription: 'Le prénom de la personne, par ex."Jane".',
          },
          response: {
            dashboardUrlDescription: "L'URL unique du tableau de bord de chaque personne",
            header:
              "Une fois soumis, vous recevrez un fichier CSV mis à jour contenant les colonnes supplémentaire suivantes:",
            shareUrlDescription: "L'URL unique du tableau de bord de chaque personne",
            statusDescription:
              "Indique si une inscription a été créée ou la raison pour laquelle elle ne l'a pas été. Cela peut être dû au fait qu'ils sont déjà inscrits à la campagne ou qu'ils se sont déjà inscrits via l'API d'inscription (dans ce cas, l'URL de partage existant est inclus).",
          },
          openButtonText: "Quelles sont les en-têtes de colonne obligatoires?",
          title: "En-têtes de colonne obligatoires",
        },
        subtitle:
          "Téléchargez un fichier CSV contenant les détails des personnes pour lesquelles vous souhaitez faire l'inscription.",
        limit: "La limite est de 200 000 lignes par téléchargement.",
      },
      transactionTracking: {
        fileImport: {
          buttonLabel: "Importer les transactions",
          checkboxLabel: "Ne pas valider l'éligibilité des parrainages",
        },
        header: "Suivi de la Transaction ",
        history: {
          header: "Historique",
          tableHeaders: {
            campaign: "Campagne",
            referrals: "Parrainages",
            rejected: "Annulé",
            status: "Statut",
            successful: "Confirmé",
            transactions: "Transactions",
            unsuccessful: "Échec",
            uploadedAt: "Téléchargé À",
            uploadedBy: "Téléchargé Par",
          },
        },
        modal: {
          clientDataContent:
            "Métadonnées supplémentaires spécifiées par le client et associées au client qui a effectué la transaction, par example ClientData_numéro_de_téléphone, ClientData_numéro_de_membre",
          customFieldContent:
            "Si vous utilisez un seul champ personnalisé pour identifier les filleuls, ce champ peut contenir des informations telles qu'un numéro de membre ou un numéro de téléphone. **Remarque : si le filleul possède déjà un champ personnalisé, ce champ le remplacera. Si vous laissez cette colonne vide, l'ancienne valeur existante restera en place. Par ex. 07123456789",
          customFieldsContent:
            'Si vous utilisez plusieurs champs personnalisés pour identifier les filleuls, ces champs peuvent contenir des informations telles qu\'un numéro de membre ou un numéro de téléphone e.g. "CustomField_phone_number", "CustomField_membership_number".',
          emailAddressContent: 'L\'adresse email du client par ex. "jane@smith.com"',
          fullNameHContent: 'Le nom complet du client. Format "Prénom, Nom", par ex. "Jane, Smith"',
          localeContent:
            "La langue préférée du client - applicable uniquement pour les marchés multilingues.",
          marketingOptinContent:
            "Indique si le client a choisi de recevoir des emails marketing, c'est-à-dire True ou False. Si elle est omise, la valeur par défaut est False.",
          orderNumberContent:
            "Cette valeur peut être tout identifiant unique que vous utilisez pour identifier les transactions, par ex. TRANSACTION12345678",
          orderValueContent:
            "La valeur de la transaction. Exclure les symboles monétaires, format XXX.YY, par ex. 12.89",
          orderTimestampContent:
            "Date de la transaction complétée, sous forme d'horodatage UNIX, par ex. 1321009871 (la date ne doit pas être antérieure de plus de quatre ans à la date de tracking)",
          optionalText: "Facultatif",
          title: "En-têtes de colonne obligatoires",
          referrerEmailContent:
            "L'adresse email du parrain par ex. \"john@doe.com\" (uniquement requis s'il n'y a pas de code pour le bon d'achat).",
          voucherCodeContent:
            "Le code attribué au client ou utilisé par le client au moment de la transaction (uniquement requis s'il n'y a pas d'email du parrain).",
          rewardCategory:
            "Si la campagne est configurée avec des catégories de récompenses - c'est-à-dire pour attribuer différents types de récompenses pour différents types de transactions, vous pouvez saisir la catégorie de récompense ici.",
        },
        showRequiredModalColumnsButtonText: "Quelles sont les en-têtes de colonne obligatoires?",
        subtitle:
          "Chaque ligne du fichier CSV doit comprendre au moins le code du bon d'achat utilisé par le client ou l'adresse email du parrain.",
      },
      textArea: {
        noLabelText: "sans étiquette",
      },
    },
    campaign: {
      editor: {
        modalTitle: "Campagne: {{campaignName}}",
      },
      header: "Campagne",
      missingCustomFieldText: "Non fourni",
      referredByDateLabel: "Parrainage en cours",
      referredByFieldLabel: "Parrainé par",
      revalidateButtonText: "Revalider",
      subheader: "Date d'Inscription",
      validator: {
        buttonLabel: "Soumettre",
        errorMessage: "Échec car l'utilisateur n'est pas dans l'état conforme",
        formPrompt:
          "Veuillez noter que le traitement de cette demande peut prendre un certain temps. ",
        modalTitle: "Soumettre pour revalidation",
      },
    },
    countries: countryCodeObject.reduce(
      (array, { name }) => ({
        ...array,
        [name]: [...(array[name] || []), name][0],
      }),
      {}
    ),
    cis: {
      headerText: "Profil",
      noResultErrorMessage: "Impossible de récupérer le profil CIS",
      retryButtonText: "Réessayer",
    },
    components: {
      textfield: {
        characters: "{{count}} caractères",
        characters_one: "{{count}} caractère",
      },
      mobileNumberDropdown: {
        flagAltText: "Drapeau de {{country}}",
      },
      copyText: {
        tooltipMessage: "Copier le lien",
        notificationMessage: "Lien copié !",
      },
      openNewTab: {
        tooltipMessage: "Cela vous amènera à votre tableau de bord de marketing partenaires",
      },
      multiSelectFilter: {
        header: "Liste des filtres",
        allSelected: "Sélectionnés",
        countSelected: "{{count}} Sélectionné",
      },
      checkboxDropdown: {
        noOptions: "Aucune option",
      },
    },
    cta: {
      changeMarket: "Changer de marché",
      signOut: "Se déconnecter",
    },
    fileImport: {
      fileSelect: {
        buttonText: "Naviguer",
        infoText: "Télécharger le fichier CSV",
      },
    },
    fileSelect: {
      buttonText: "Naviguer",
      infoText: "Sélectionner un fichier",
    },
    forms: {
      errors: {
        generic: {
          invalid:
            "Il y a eu un problème pour soumettre ce formulaire, veuillez réessayer plus tard.",
        },
        imageUpload: {
          format: "Veuillez télécharger une image au format png ou jpeg",
          size: "Veuillez télécharger une image plus petite",
        },
        referral: {
          unsuccessful: "La création du parrainage a échoué.",
          pending_count_unsuccessful:
            "Impossible d'obtenir le nombre de parrainages en attente par campagne",
          duplicate_order: "Cette commande est un doublon",
          self_referral: "Les parrains ne peuvent pas se parrainer eux-mêmes",
          no_referrer: "Ce parrain n'a pas été trouvé",
          existing_referrer: "Cette campagne ne permet pas de parrainer des clients existants",
          referral_limit_reached: "Ce parrain a atteint sa limite de parrainages",
          unique_referred_friends_reached: "Pas plus de 5 amis uniques ne peuvent être parrainés",
        },
        businessName: {
          filled: "Veuillez entrer le nom de l'entreprise ou de la marque",
        },
        mobile: {
          filled: "Veuillez entrer le numéro de téléphone",
          invalid: "Veuillez entrer un numéro de téléphone valide",
        },
        mobileCountryCode: {
          filled: "Veuillez entrer le code du pays",
        },
        email: {
          format:
            "L'adresse email que vous avez saisie est invalide. Veuillez verifier qu'elle soit correcte.",
          not_unique:
            "L'adresse de courriel existe déjà sous un autre programme. Veuillez s'il vous plaît en utiliser une autre.",
          filled: "Veuillez saisir une valeur",
        },
        confirmationCode: {
          invalid:
            "Le code que vous avez saisie est incorrecte, veuillez vérifier votre email et réessayer.",
        },
        passwordConfirmation: {
          doesnt_match:
            "Les mots de passe que vous avez saisis ne correspondent pas, veuillez vous assurer qu'ils sont corrects.",
        },
        password: {
          invalid: passwordFormatError,
          format: passwordFormatError,
        },
        firstName: {
          min_size: "Ce nom n'est pas assez long.",
          filled: "Veuillez entrer le prénom",
          invalid: "Avez-vous correctement saisi votre nom ?",
        },
        lastName: {
          filled: "Veuillez entrer le nom de famille",
          invalid: "Avez-vous correctement saisi votre nom ?",
        },
        signupReason: {
          key: "Veuillez entrer des notes ou informations supplémentaires",
        },
        audienceSize: {
          int: "Veuillez entrer un nombre",
        },
        customField: {
          format: "Ceci n'est pas le bon format.",
          filled: "Veuillez saisir une valeur",
          invalid: "Cette valeur n'est pas acceptée",
        },
        testInputId: {
          filled: "Veuillez saisir une valeur",
          format: "La valeur n'est pas dans le bon format",
        },
        testAnotherInputId: {
          filled: "Veuillez saisir une autre valeur",
          format: "La valeur n'est pas dans le bon format",
        },
        campaign_id: {
          filled: "Veuillez sélectionner une campagne",
        },
        campaignId: {
          key: "Veuillez sélectionner une campagne",
        },
        registration: {
          not_allowed: "L'utilisateur n'est pas autorisé à s'inscrire en tant que parrain.",
        },
        termsOptIn: {
          filled: "Veuillez accepter les conditions",
        },
        emailComplianceOptIn: {
          filled: "Veuillez accepter les conditions de conformité d'emails",
        },
        marketingOptIn: {
          filled: "Veuillez accepter les termes marketing",
        },
        fileImport: {
          empty: "Le fichier CSV est vide ",
          missing_headers:
            "Il manque des en-têtes au fichier CSV, veuillez revoir les en-têtes de colonnes obligatoires ci-dessus et les inclure comme en-têtes",
          unprocessable:
            "Le fichier CSV est invalide, veuillez revoir les en-têtes de colonnes obligatoires ci-dessous et soumettre à nouveau le fichier",
          upload_error:
            "Le service de téléchargement a renvoyé une erreur. Veuillez réessayer plus tard",
          included_in:
            "Il y a eu un problème pour soumettre ce formulaire, veuillez réessayer plus tard",
          data_errors:
            "Il y a eu un problème avec les donnés CSV et le fichier n'a pas été téléchargé",
        },
        textImport: {
          empty: "La valeur est vide",
          missing_headers:
            "Il y a eu un problème pour soumettre ce formulaire, veuillez réessayer plus tard",
          data_errors: "Il y a eu un problème avec les données importées.",
        },
        bulkActions: {
          load_history:
            "Il y a eu un problème de chargement de l'historique. Veuillez réessayer plus tard",
        },
        expiryDate: {
          date: "Veuillez entrer une date valide",
          invalid: "Veuillez vous assurer que la date est dans le futur et réessayez",
        },
        audienceLocation: {
          min_size: "Veuillez sélectionner au moins un emplacement",
        },
        partnerAssets: {
          partnerAssets:
            "Il y a eu un problème pour soumettre ce formulaire, veuillez réessayer plus tard",
          characterLimit: "Limite de caractères atteinte",
        },
        partnerRewards: {
          setRewardsError:
            "Il y a eu un problème pour définir les récompenses, veuillez réessayer plus tard",
          getAvailableRewardsError:
            "Il y a eu un problème pour obtenir les récompenses disponibles, veuillez réessayer plus tard",
        },
        locale: {
          type: "Veuillez sélectionner une langue",
          filled: "Veuillez sélectionner une langue",
        },
      },
      labels: {
        partner: {
          businessName: "Nom de l'entreprise ou de la marque du partenaire",
          firstName: "Prénom du contact",
          lastName: "Nom de famille du contact",
          email: "Adresse e-mail du contact",
          mobile: "Numéro de téléphone du contact",
          jobTitle: "Titre du poste du contact",
          industrySector: "Secteur d'activité",
          language: "Langue",
          businessCountry: "Emplacement du partenaire",
          twitter: "Twitter du partenaire",
          instagram: "Instagram du partenaire",
          facebook: "Facebook du partenaire",
          linkedin: "LinkedIn du partenaire",
          snapchat: "Snapchat du partenaire",
          youtube: "YouTube du partenaire",
          website: "Site web du partenaire",
          signupReason: "Informations supplémentaires ou notes sur le partenaire",
          audienceSize: "Taille de l'audience du partenaire",
          audienceLocation: "Où se trouve l'audience du partenaire?",
          worldSector: "Sélectionnez un secteur mondial",
        },
        businessName: "Nom de l'entreprise ou de la marque du partenaire",
        firstName: "Prénom",
        lastName: "Nom",
        email: "Adresse e-mail",
        orderNumber: "Numéro de commande",
        rewardCategory: "Catégorie de récompense",
        noRewardCategory: "Aucune catégorie de récompense",
        selectRewardCategory: "Choisissez une catégorie de récompense",
        mobile: "Numéro de téléphone",
        expiryDate: "Entrez la nouvelle date d'expiration",
        mandatoryField: "* indique les informations requises",
        campaign: "Sélectionnez une campagne",
        campaignPlaceholder: "Sélectionnez une campagne",
      },
      headers: {
        addPartner: "Ajouter un Partenaire",
        editProfile: "Modifier le Profil",
      },
      placeholders: {
        select: "Choisir une option",
      },
      hints: {
        expiryDate: "Format: dd/mm/yyyy",
      },
      infoText: {
        twitter: "Merci d'entrer votre nom d'utilisateur Twitter",
        instagram: "Merci d'entrer votre nom d'utilisateur Instagram",
        facebook: "Merci d'entrer votre nom d'utilisateur Facebook",
        linkedin: "Merci d'entrer votre nom d'utilisateur LinkedIn",
        youtube: "Merci d'entrer votre nom d'utilisateur YouTube",
        snapchat: "Merci d'entrer votre nom d'utilisateur Snapchat",
        signupReason:
          "Parlez nous de vous/votre entreprise. Merci d'inclure des informations sur votre industrie/secteur et le type de clients que vous avez",
        audienceSize: "En moyenne, combien de visiteurs avez vous sur votre site/ réseaux sociaux",
        audienceLocation: "Sélectionner la localisation principale de votre public",
      },
    },
    fulfilments: {
      header: "Récompenses",
      tableHeaders: {
        name: "Nom",
        dateEarned: "date d'acquisition",
        source: "source",
        expiryDate: "date d'expiration",
        status: "Statut",
      },
      source: {
        referral: "Parrainage",
      },
      infoBox: "Lorsque vous voyez l'icône <1></1>, vous pouvez la survoler pour en savoir plus.",
      actions: {
        errorRewardEmailResenMessage:
          "Il y a eu un problème avec l'envoi de l'email pour cette récompense",
        resentOption: "Renvoyer l'email de récompense ",
        successRewardEmailResentMessage: "Email de récompense renvoyé",
        changeExpiry: "Modifier l'expiration",
        expire: "Expirer la réalisation",
        unexpire: "Annuler l'expiration de la réalisation",
      },
      changeExpiryDateText: "Modifier l'expiration",
      unexpireModalText: "Annuler l'expiration",
      expireModalText: "Êtes-vous sûr ?",
      expireModalSubtitle:
        "Si vous cliquez sur expirer, la réalisation liée à ce parrainage sera expirée.",
      expireModalButton: "Expirer",
      notifications: {
        successfulExpiryDateUpdate: "La date d'expiration a été prolongée",
        successfulExpiry: "La réalisation a été expirée",
        expiryErrorMessage: "Un problème est survenu lors de la mise à jour de cette réalisation",
      },
      paymentProviderStatuses: {
        PAYPAL_BLOCKED: "Le paiement a été bloquée.",
        PAYPAL_CURRENCY_NOT_SUPPORTED_FOR_RECEIVER:
          "Cette devise n'est malheureusement pas prise en charge par le compte sur lequel vous souhaitez effectuer le paiement. Merci de bien vouloir essayer avec une devise différente.",
        PAYPAL_FAILED: "Votre demande de virement n'a pas pu être effectué.",
        PAYPAL_INVALID_EMAIL: "Email invalide",
        PAYPAL_ONHOLD: "Le paiement est en cours d'examen et est en attente.",
        PAYPAL_PENDING: "Le paiement a été reçue et sera traitée sous peu.",
        PAYPAL_PENDING_RECIPIENT_NON_HOLDING_CURRENCY_PAYMENT_PREFERENCE:
          "Ce paiement est en attente de confirmation par le destinataire, qui souhaite vérifier chaque paiement émis à partir de la devise que vous avez choisie. Merci de bien vouloir revenir plus tard pour consulter le statut de votre paiement.",
        PAYPAL_RECEIVER_ACCOUNT_LIMITATION:
          "Raison: Le compte destinataire fait l'objet de restrictions.",
        PAYPAL_RECEIVER_ACCOUNT_LOCKED:
          "Raison: Votre demande de virement n'a pas pu être effectué car le compte destinataire est clôturé ou a été bloqué.",
        PAYPAL_RECEIVER_COUNTRY_NOT_ALLOWED:
          "Raison: Votre demande de virement n'a pas pu être effectué car le compte destinataire est détenu au sein d'une zone non supportée ou restreinte.",
        PAYPAL_RECEIVER_STATE_RESTRICTED:
          "Raison: Votre demande de virement n'a pas pu être effectué car le compte destinataire est détenu au sein d'une zone non supportée ou restreinte.",
        PAYPAL_RECEIVER_UNCONFIRMED: "Raison: Le compte destinataire n'a pas été validé.",
        PAYPAL_RECEIVER_UNREGISTERED: "Raison: Le compte destinataire n'existe pas.",
        PAYPAL_RECEIVER_YOUTH_ACCOUNT:
          "Raison: Votre demande de virement a été refusé pour la raison suivante : destinataire âgé de moins de 18 ans.",
        PAYPAL_RECEIVING_LIMIT_EXCEEDED:
          "Raison: Le destinataire ne peut pas recevoir ce paiement car il dépasse son plafond autorisé.",
        PAYPAL_REGULATORY_BLOCKED:
          "Cette transaction a été refusée pour des raisons de conformité réglementaire.",
        PAYPAL_RETURNED:
          "Le destinataire n'a pas réclamé la somme versée, elle vous est donc restituée.",
        PAYPAL_REFUNDED: "Le paiement a été remboursée.",
        PAYPAL_REVERSED: "Le paiement a été annulée.",
        PAYPAL_RISK_DECLINE:
          "Raison: Votre demande de virement a été refusé pour la raison suivante : risque de fraude.",
        PAYPAL_UNCLAIMED: "Le bénéficiaire n'a pas de compte PayPal.",
        HYPERWALLET_CREATED: "Cette demande de paiement a été reçue et sera traitée.",
        HYPERWALLET_SCHEDULED: "Cette demande de paiement a été reçue et sera traitée.",
        HYPERWALLET_PENDING_ACCOUNT_ACTIVATION: "En attente d'activation du compte.",
        HYPERWALLET_PENDING_ID_VERIFICATION: "En attente de vérification d'identité.",
        HYPERWALLET_PENDING_TAX_VERIFICATION: "En attente de vérification fiscale.",
        HYPERWALLET_PENDING_TRANSFER_METHOD_ACTION: "En attente de méthode de transfert.",
        HYPERWALLET_PENDING_TRANSACTION_VERIFICATION: "En attente de vérification de transaction.",
        HYPERWALLET_IN_PROGRESS: "Cette demande de paiement a été reçue et sera traitée. ",
        HYPERWALLET_UNCLAIMED: "Non réclamé",
        HYPERWALLET_CANCELLED: "Annulé",
        HYPERWALLET_FAILED:
          "Cette demande de paiement a échoué, alors les fonds n'ont pas été déduits.",
        HYPERWALLET_RECALLED: "Rappelé",
        HYPERWALLET_RETURNED:
          "Le bénéficiaire n'a pas réclamé ce paiement, alors les fonds ont été retournés.",
        HYPERWALLET_EXPIRED: "Expiré",
        HYPERWALLET_CONSTRAINT_VIOLATIONS:
          "Cette demande contient des caractères spéciaux non valides ou un nombre insuffisant de caractères.",
      },
    },
    global: {
      add: "Ajouter",
      back: "Retour",
      cancel: "Annuler",
      campaigns: "Campagne",
      close: "Fermer",
      confirm: "Confirmer",
      edit: "Editer",
      email: "Email",
      error: "erreur",
      customers: "Clients",
      download: "Télécharger",
      no: "Non",
      none: "Aucun",
      ok: "Ok",
      or: "ou",
      referrals: "Parrainages",
      reporting: "Rapports",
      integrations: "Intégrations",
      save: "Enregistrer",
      search: "Rechercher",
      submitted: "envoyé",
      success: "succès",
      undo: "annuler",
      yes: "Oui",
      apply: "Appliquer",
      all: "Tout",
      clearAll: "Tout effacer",
      expandSection: "Développer la section",
      collapseSection: "Réduire la section",
      filter: "Filtrer:",
      selectAll: "Sélectionner tout",
      deselectAll: "Tout désélectionner",
    },
    industrySector: [
      "Actualités sur Internet",
      "Administration",
      "Administration publique",
      "Affaires étrangères",
      "Affaires militaires et internationales",
      "Agences de collecte de fonds",
      "Agences et courtiers d'assurances",
      "Agences publiques",
      "Agents et courtiers immobiliers dans le locatif",
      "Agriculture",
      "Agriculture, élevage, gestion forestière",
      "Aménagement d'intérieur",
      "Animation et post-production",
      "Applications mobiles de jeux",
      "Architecture et urbanisme",
      "Artistes et écrivains",
      "Arts du spectacle et sports avec spectateurs",
      "Arts vivants",
      "Associations de secteurs",
      "Assurances",
      "Avocats",
      "Bars, tavernes et discothèques",
      "Bâtiment",
      "Bibliothèques",
      "Blogs",
      "Bourses de valeurs mobilières et matières premières",
      "Brasseries",
      "Bureaux exécutifs",
      "Bureaux législatifs",
      "Cabinets médicaux",
      "Capitaux de capital-risque et capital-investissement",
      "Centres d'appels téléphoniques",
      "Centres de planning familial",
      "Centres de recherches",
      "Centres de soins ambulatoires",
      "Chantiers navals",
      "Chiropracteurs",
      "Circuits de course",
      "Cirques et spectacles de magie",
      "Collecte des déchets",
      "Commerce de détail",
      "Commerce de détail d'appareils électriques et électroniques",
      "Commerce de détail d'articles de luxe et joaillerie",
      "Commerce de détail d'équipement de bureau",
      "Commerce de détail d'essence",
      "Commerce de détail d'instruments de musique",
      "Commerce de détail de fleurs",
      "Commerce de détail de fournitures artistiques",
      "Commerce de détail de fournitures de bureau et cadeaux",
      "Commerce de détail de livres et journaux papier",
      "Commerce de détail de matériaux de construction et matériel de jardinage",
      "Commerce de détail de matériaux recyclables et marchandises de seconde main",
      "Commerce de détail de mobilier et ameublement",
      "Commerce de détail de mode et habillement",
      "Commerce de détail de produits alimentaires et boissons",
      "Commerce de détail de produits d'épicerie",
      "Commerce de détail de produits de santé, de soin et d'hygiène",
      "Commerce de détail de véhicules à moteur",
      "Commerce de détail en ligne et vente par correspondance",
      "Commerce de gros",
      "Commerce de gros d'appareils électriques et électroniques",
      "Commerce de gros d'articles de luxe et joaillerie",
      "Commerce de gros d'équipements de quincaillerie, plomberie et chauffage",
      "Commerce de gros de boissons alcoolisées",
      "Commerce de gros de chaussures",
      "Commerce de gros de machines industrielles",
      "Commerce de gros de matériaux de construction",
      "Commerce de gros de matériaux recyclables",
      "Commerce de gros de matériel de couture et d'habillement",
      "Commerce de gros de matériel de photographie",
      "Commerce de gros de matériel informatique",
      "Commerce de gros de médicaments et divers",
      "Commerce de gros de métaux et minéraux",
      "Commerce de gros de mobilier et ameublement",
      "Commerce de gros de pétrole et produits pétroliers",
      "Commerce de gros de produits agricoles bruts",
      "Commerce de gros de produits alimentaires et boissons",
      "Commerce de gros de produits chimiques et dérivés",
      "Commerce de gros de produits d'import et export",
      "Commerce de gros de produits en papier",
      "Commerce de gros de véhicules à moteur et pièces détachées",
      "Commerce et développement international",
      "Compagnies aériennes et aviation",
      "Compagnies d'assurances",
      "Compagnies de danse",
      "Compagnies de théâtre",
      "Comptabilité",
      "Conseil en exploitation",
      "Conseil en investissement",
      "Conseil en sous-traitance et externalisation à l'étranger",
      "Construction d'axes routiers, de rues et de ponts",
      "Construction d'installations d'utilité publique",
      "Construction de bâtiments",
      "Construction de bâtiments non résidentiels",
      "Construction de bâtiments résidentiels",
      "Construction de logements et installations publiques",
      "Contenu professionnel",
      "Contenus rédactionnels",
      "Courtiers en prêts",
      "Demi-pensions, auberges, chambres d'hôtes",
      "Dentistes",
      "Design graphique",
      "Développement de logiciels",
      "Développement de logiciels personnalisés de systèmes informatiques",
      "Diffusion télévisuelle et radio",
      "Distilleries",
      "Distribution d'eau et systèmes d'irrigation",
      "Distribution de films et vidéos",
      "Distribution de gaz naturel",
      "Distribution de vapeur et climatisation",
      "Écoles de cosmétique et de coiffure",
      "Écoles de langue",
      "Écoles de secrétariat",
      "Écoles des beaux-arts",
      "Édition de journaux",
      "Édition de livres",
      "Édition de livres et périodiques",
      "Édition de partitions musicales",
      "Édition de périodiques",
      "Édition sur Internet",
      "Éducation",
      "EHPAD",
      "Élevage",
      "Élevage et pêche",
      "Enquêteurs d'assurances et services actuariels",
      "Enregistrement sonore",
      "Enseignement primaire et secondaire",
      "Enseignement supérieur",
      "Entreposage et stockage",
      "Entretien d'équipement électronique et de précision",
      "Entretien de machines commerciales et industrielles",
      "Équipements collectifs",
      "Équipes et clubs sportifs",
      "Établissements d'épargne",
      "Études de marché",
      "Exploitation et maintenance de systèmes informatiques",
      "Exploitation minière",
      "Exploitations viticoles",
      "Extraction de charbon",
      "Extraction de gaz naturel",
      "Extraction de minerais métalliques",
      "Extraction de minerais non métalliques",
      "Extraction de pétrole",
      "Fabrication d'accessoires de mode",
      "Fabrication d'appareils électroménagers",
      "Fabrication d'appareils électroménagers, électriques et électroniques",
      "Fabrication d'articles de sport",
      "Fabrication d'emballages et conteneurs",
      "Fabrication d'équipement de CVC et réfrigération",
      "Fabrication d'équipements audio et vidéo",
      "Fabrication d'équipements de communications",
      "Fabrication d'équipements de transport",
      "Fabrication d'équipements électriques",
      "Fabrication d'équipements médicaux",
      "Fabrication d'équipements pour éclairage électrique",
      "Fabrication d'équipements routiers",
      "Fabrication d'instruments de mesure et de contrôle",
      "Fabrication d'outils pour le bâtiment",
      "Fabrication de boissons",
      "Fabrication de caoutchouc artificiel et fibres synthétiques",
      "Fabrication de chaudières, cuves et conteneurs maritimes",
      "Fabrication de chaussures",
      "Fabrication de composants pour l'industrie aéronautique et aérospatiale",
      "Fabrication de conserves de fruits et légumes",
      "Fabrication de coutellerie et d'outils manuels",
      "Fabrication de machines",
      "Fabrication de machines agricoles et minières, engins de chantier",
      "Fabrication de machines automatiques",
      "Fabrication de machines de métallurgie",
      "Fabrication de machines industrielles",
      "Fabrication de machines pour le secteur des services et du commerce",
      "Fabrication de matelas et stores",
      "Fabrication de matériel informatique",
      "Fabrication de matériel informatique et électronique",
      "Fabrication de matières premières chimiques",
      "Fabrication de métaux architecturaux et structurels",
      "Fabrication de minéraux abrasifs et non métalliques",
      "Fabrication de mobilier et ameublement",
      "Fabrication de mobilier et installations pour bureaux",
      "Fabrication de mobilier pour particuliers et institutions",
      "Fabrication de moteurs et d'équipements de transmission d'énergie",
      "Fabrication de nourriture pour animaux",
      "Fabrication de peintures, enduits et adhésifs",
      "Fabrication de pièces de véhicules à moteur",
      "Fabrication de produits alimentaires et boissons",
      "Fabrication de produits carnés",
      "Fabrication de produits chimiques",
      "Fabrication de produits chimiques agricoles",
      "Fabrication de produits de la mer",
      "Fabrication de produits de soin et hygiène",
      "Fabrication de produits du bois",
      "Fabrication de produits du papier et des matières forestières",
      "Fabrication de produits en argile et matériaux réfractaires",
      "Fabrication de produits en caoutchouc",
      "Fabrication de produits en chaux et plâtre",
      "Fabrication de produits en verre",
      "Fabrication de produits laitiers",
      "Fabrication de produits pétroliers et transformation du charbon",
      "Fabrication de produits pharmaceutiques",
      "Fabrication de produits plastiques et en caoutchouc",
      "Fabrication de produits usinés et de fixations",
      "Fabrication de ressorts et de produits en fil métallique",
      "Fabrication de sacs à main",
      "Fabrication de savon et de produits d'entretien ménager",
      "Fabrication de semi-conducteurs",
      "Fabrication de semi-conducteurs pour énergies renouvelables",
      "Fabrication de soupapes, billes et cylindres en métal",
      "Fabrication de sucre et produits de confiserie",
      "Fabrication de supports magnétiques et optiques",
      "Fabrication de véhicules à moteur",
      "Fabrication de verre, céramique et béton",
      "Fabrication de vêtements",
      "Fabrication de viennoiseries",
      "Fabrication pour l'industrie de la défense et industrie spatiale",
      "Fiducies et successions",
      "Films et enregistrements sonores",
      "Fonds d'assurance et au bénéfice du personnel",
      "Fonds de pension",
      "Fonds et trusts",
      "Forces armées",
      "Formation aux compétences professionnelles",
      "Formation de pilote de ligne",
      "Formation en sport et animation",
      "Formation et assistance relatives aux systèmes informatiques",
      "Formation technique et professionnelle",
      "Fournisseurs de formations en ligne",
      "Fournisseurs de services de divertissement",
      "Fourniture de l'équipement du bâtiment",
      "Fourniture de la structure et de l'habillage du bâtiment",
      "Fourniture de services d'utilité publique",
      "Fourniture des finitions du bâtiment",
      "Fourniture du second œuvre",
      "Génie civil",
      "Gestion de portefeuilles",
      "Gestion de programmes portant sur l'air, les eaux et les déchets",
      "Gestion des services d'utilité publique",
      "Gestion forestière et abattage",
      "Hôpitaux",
      "Hôpitaux et services de santé",
      "Horticulture",
      "Hôtellerie",
      "Hôtels et motels",
      "Immobilier locatif",
      "Immobilier non résidentiel locatif",
      "Immobilier résidentiel locatif",
      "Industrie pétrolière et gazière",
      "Industrie pétrolière et gazière et exploitation minière",
      "Infrastructure et analyses de données",
      "Installation et suppression de systèmes informatiques",
      "Installations de loisirs",
      "Installations de ski",
      "Installations publiques et aménagement urbain",
      "Institutions correctionnelles",
      "Institutions religieuses",
      "Intermédiation bancaire",
      "Jeux électroniques",
      "Kinésithérapeutes, ergothérapeutes et orthophonistes",
      "Laboratoires d'analyses médicales",
      "Levées de fonds",
      "Location d'équipement commercial et industriel",
      "Location de biens de consommation",
      "Logements sociaux",
      "Maintien de l'ordre",
      "Marchés des capitaux",
      "Maroquinerie",
      "Médecines alternatives",
      "Médecins",
      "Médias audio et vidéo en ligne",
      "Morcellement de terrains",
      "Musées",
      "Musées, sites historiques et zoos",
      "Musiciens",
      "Opérateurs de télécommunications",
      "Optométristes",
      "Organisation d'événements",
      "Organisations à but non lucratif",
      "Organisations de voyages",
      "Organisations politiques",
      "Organismes civiques et sociaux",
      "Organismes professionnels",
      "Parcs d'attractions et jeux d'arcade",
      "Pêche",
      "Photographie",
      "Plateformes de réseaux sociaux",
      "Plateformes de veille stratégique",
      "Plateformes de vente en ligne",
      "Pompiers",
      "Production audiovisuelle",
      "Production d'électricité",
      "Production d'électricité issue de combustibles fossiles",
      "Production d'électricité issue de l'énergie géothermique",
      "Production d'électricité issue de l'éolien",
      "Production d'électricité issue de la biomasse",
      "Production d'électricité issue du nucléaire",
      "Production d'électricité issue du solaire",
      "Production d'hydroélectricité",
      "Production de métaux primaires",
      "Production de plastiques",
      "Production de tabac",
      "Production et distribution de médias de diffusion",
      "Production industrielle",
      "Production textile",
      "Produits d'information et culturels",
      "Produits de mise en réseau informatique",
      "Produits en métal transformés",
      "Produits logiciels de sécurité des données",
      "Produits logiciels informatiques pour mobiles",
      "Produits logiciels informatiques pour ordinateurs",
      "Produits logiciels intégrés",
      "Programmation pour services par satellite ou câble",
      "Programmes d'administration de l'éducation",
      "Programmes d'assistance publique",
      "Programmes de préservation et protection",
      "Programmes de qualité de l'environnement",
      "Programmes de transports",
      "Programmes économiques",
      "Recherche en biotechnologie",
      "Recherche en nanotechnologie",
      "Recherche spatiale et technologie",
      "Recrutement",
      "Réfection de tissus d'ameublement et réparation de meubles",
      "Règlement extrajudiciaire de conflits",
      "Réparation de chaussures et d'articles en cuir",
      "Réparation et entretien",
      "Réparation et entretien de véhicules",
      "Restaurants",
      "Salles de jeux d'argent et casinos",
      "Santé et services sociaux",
      "Santé publique",
      "Secteur médico-psychologique",
      "Sécurité civile",
      "Sécurité et enquêtes",
      "Sécurité informatique et des réseaux",
      "Services à la personne",
      "Services à la population",
      "Services administratifs et fonctions supports",
      "Services aux animaux de compagnie",
      "Services aux consommateurs",
      "Services aux particuliers",
      "Services aux personnes âgées et en situation de handicap",
      "Services bancaires",
      "Services d'aide à l'insertion professionnelle",
      "Services d'ambulance",
      "Services d'aménagement paysager",
      "Services d'assistance temporaire",
      "Services d'impression",
      "Services d'information",
      "Services d'investissement",
      "Services d'urgence et d'assistance",
      "Services de bien-être et fitness",
      "Services de blanchisserie et de pressing",
      "Services de bus scolaires et d'entreprise",
      "Services de cars interurbains et ruraux",
      "Services de conception",
      "Services de conception de systèmes informatiques",
      "Services de conciergerie et de blanchisserie",
      "Services de données de systèmes informatiques",
      "Services de garderie et crèche",
      "Services de gestion des eaux, eaux usées, vapeur et climatisation",
      "Services de gestion stratégique",
      "Services de levée de fonds à des fins caritatives",
      "Services de lobbying",
      "Services de location d'équipement",
      "Services de logement",
      "Services de marketing",
      "Services de nettoyage et d'entretien",
      "Services de publicité",
      "Services de recherche",
      "Services de recherche de cadres",
      "Services de relations publiques et communications",
      "Services de ressources humaines",
      "Services de restauration",
      "Services de restauration mobile",
      "Services de santé à domicile",
      "Services de soins à la personne",
      "Services de systèmes de sécurité",
      "Services de taxis et limousines",
      "Services de transport par navette et service adapté",
      "Services de transports en commun urbains",
      "Services de vigiles et agents de sécurité",
      "Services et conseil aux entreprises",
      "Services et conseil informatiques",
      "Services financiers",
      "Services immobiliers et de location d'équipements",
      "Services juridiques",
      "Services mobiles",
      "Services postaux",
      "Services pour l'environnement",
      "Services professionnels et techniques",
      "Services vétérinaires",
      "Sites historiques",
      "Sociétés de portefeuille",
      "Sports devant spectateurs",
      "Système judiciaire",
      "Télécommunications",
      "Télécommunications par satellite",
      "Terrains de golf et country clubs",
      "Tests et évaluation de systèmes informatiques",
      "Traduction et adaptation",
      "Traitement et élimination des déchets",
      "Traitements des métaux",
      "Traiteurs",
      "Transmission, contrôle et distribution d'énergie électrique",
      "Transport de fret et de colis",
      "Transport de tourisme",
      "Transport ferroviaire",
      "Transport maritime",
      "Transport par pipeline",
      "Transport routier",
      "Transport terrestre de voyageurs",
      "Transport, logistique et stockage",
      "Tribunaux de justice",
      "Zoos et jardins botaniques",
    ],
    worldSector: [
      "Afrique",
      "Australasie",
      "Asie de l'Est",
      "Europe",
      "Amérique centrale",
      "Amérique du Nord",
      "Amérique du Sud",
      "Asie du Sud",
    ],
    languages: {
      ar: "Arabe (ar)",
      ms: "Malais (ms)",
      eu: "Basque (eu)",
      bg: "Bulgare (bg)",
      ca: "Catalán (ca)",
      cs: "Tchèque (cs)",
      "zh-CN": "Chinois - Simplifie (zh-CN)",
      "zh-TW": "Chinois - Traditionnel (zh-TW)",
      da: "Danois (da)",
      nl: "Néerlandais (nl)",
      en: "Anglais (en)",
      "en-US": "Anglais (É.-U.) (en-US)",
      fi: "Finnois (fi)",
      fr: "Français (fr)",
      "fr-CA": "Français - Canada (fr-CA)",
      gl: "Galicien (gl)",
      hu: "Hongrois (hu)",
      de: "Allemand (de)",
      "de-informal": "Allemand - Informel (de-informal)",
      "de-AT-informal": "Allemand - Autriche (de-AT-informal)",
      "de-CH-informal": "Allemand - Suisse (de-CH-informal)",
      el: "Grec (el)",
      id: "Indonésien (id)",
      it: "Italien (it)",
      ja: "Japonais (ja)",
      ko: "Coréen (ko)",
      nb: "Norvégien (nb)",
      pl: "Polonais (pl)",
      pt: "Portugais (pt)",
      "pt-BR": "Portugais - Brésil (pt-BR)",
      ro: "Roumain (ro)",
      ru: "Russe (ru)",
      es: "Espagnol (es)",
      sv: "Suédois (sv)",
      tl: "Tagalog (tl)",
      th: "Thaï (th)",
      tr: "Turc (tr)",
      vi: "Vietnamien (vi)",
    },
    marketSelector: {
      title: "Sélectionner le Marché",
      textInput: {
        label: "Filtrer les marchés",
        placeholder: "Recherche...",
      },
    },
    orders: {
      header: "Commandes",
      tableHeaders: {
        codeUsed: "Code utilisé",
        madeOn: "Adopté Le",
        referralLinked: "Parrainage associé",
        reference: "Référence",
        value: "Valeur",
      },
    },
    referrals: {
      header: "Parrainage effectué",
      tableHeaders: {
        friendName: "nom du filleul",
        referredOn: "parrainé le",
        reward: "récompense",
        status: "statut",
      },
      cancelReferralModalText: "Annuler le Parrainage",
      delayedConfirmationModal: {
        cancelReferral: {
          confirmationText: "Oui, annuler le Parrainage",
          title: "Étes-vous certain de vouloir annuler ce Parrainage?",
          undoMessage: "L'annulation a été annulée.",
        },
        closeModalText: "Non, retournez-y!",
        confirmReferral: {
          confirmationText: "Oui, confirmer le parrainage",
          content: "Ce changement est irréversible.",
          title: "Étes-vous certain de vouloir confirmer ce Parrainage?",
          undoMessage: "La confirmation a été annulée.",
        },
        restoreReferral: {
          confirmationText: "Oui, restaurer le Parrainage",
          title: "Étes-vous certain de vouloir restaurer ce parrainage à un statut en attente?",
          undoMessage: "La restauration a été annulé.",
        },
        selectRewardCategory: "Sélectionnez une catégorie de récompense",
        undoCounterText: "trop tard!",
      },
      notifications: {
        errorCancelled: "Il y a eu un problème pour annuler ce parrainage",
        errorConfirming: "Il y a eu un problème pour confirmer ce parrainage",
        errorRevertedToPending:
          "Il y a eu un problème pour restaurer ce parrainage à un statut d'attente",
        successCancelled: "Parrainage annulé avec succès",
        successConfirmed: "Parrainage confirmé avec succès",
        successRevertedToPending: "Parrainage rétabli en attente avec succès",
        noConfirmReferralWarning: "Impossible de confirmer manuellement un parrainage CIS",
      },
      optionsLabels: {
        cancelReferral: "Annuler le Parrainage",
        confirmReferral: "Confirmer le Parrainage",
        noRewardCategory: "Ne pas spécifier une catégorie de récompense",
        revertToPending: "Restaurer en attente",
      },
      ineligibilityReasons: {
        ordered_previously: "Déjà commandé",
        no_referrer: "Pas de parrain",
        self_referral: "Auto-parrainage",
        existing_referrer: "Parrain existant",
        referral_limit_reached: "Limite de parrainage atteinte",
        friend_voucher_already_redeemed: "Bon de filleul déjà réclamé",
        email_required: "Adresses mails requises",
        name_required: "Nom requis",
        terms_opt_in_required: "Option d'adhésion requis",
        unique_referred_friends_reached: "Limite de parrainage unique atteinte",
      },
    },
    reporting: {
      dashboard: {
        errorLoadingData: "Il y a eu un problème pour charger les données du rapport",
        errorTimeLineData: "Il y a eu un problème pour charger les données chronologiques",
        loadingText: "Chargement",
        modal: {
          title: "Laissez-nous vous aider",
        },
        overview: {
          contactBar: {
            modal: {
              emailSubject: "Contactez-nous",
              firstParagraph:
                "Les account managers de Buyapowa sont des experts en matière de parrainage, et il sont là pour aider votre campagne à atteindre son plein potentiel.",
              reachOutAt: "Contactez-nous",
              secondParagraph:
                "Ils seront heureux de discuter de tout ce qui concerne la mise au point <strong> de votre offre, la promotion de votre campagne </strong> et notre <strong> API d'inscriptions </strong>, ainsi que des conseils <strong> en matière de design, analyses psychologiques </strong> et bien plus encore.",
            },
            firstParagraph: "Se mettre en contact avec votre Success Manager",
            secondParagraph: "Nous pouvons vous aider à améliorer ces chiffres",
          },
          legendText: {
            first: "Vos clients existants",
            second: "Les amis de vos clients",
          },
          metricColumns: {
            confirmedReferrals: "Parrainages confirmés",
            confirmedReferralsTooltipContent:
              "Le nombre de parrainages qui ont été confirmés au cours de la période sélectionnée. Les récompenses ne sont délivrées qu'après la confirmation d'un parrainage.",
            newReferrerRegistrations: "Inscriptions de nouveaux parrainages",
            newReferrerRegistrationsTooltipContent:
              "Le nombre de clients qui se sont inscrit à votre programme de parrainage au cours de la période sélectionnée.",
            pendingReferrals: "Parrainages en attente",
            pendingReferralsTooltipContent:
              "Le nombre de parrainages en attente qui ont été créés au cours de la période sélectionnée. Un parrainage en attente doit faire l'objet d'une validation supplémentaire avant de devenir un parrainage confirmé.",
            sharers: "Partageurs",
            sharersTooltipContent:
              "Pour qu'un parrain soit considéré comme un partageur pendant la période sélectionnée, il devait appuyer sur un bouton de partage ou copier le lien",
            title: "Entre",
            uniqueFriendVisits: "Visites uniques d'un filleul",
            uniqueFriendVisitsTooltipContent:
              "Le nombre de filleuls qui ont visité votre programme après avoir été invités par un parrain inscrit",
          },
          totalConfirmedReferrals: "Total des parrainages confirmés",
          totalRegisteredReferrers: "Total des parrainages inscrits",
          totals: {
            since: "Depuis",
          },
        },
        performanceTiles: {
          confirmedReferrals: "Parrainages confirmés",
          confirmedReferralsContent:
            "Le nombre de clients parrainés qui ont été confirmés en tant que filleuls (sur la base de vos règles commerciales) au cours de la période sélectionnée.",
          confirmedReferralsPerSharer: "Parrainages confirmés par partageur",
          confirmedReferralsPerSharerContent:
            "Rapport entre le nombre de parrainages qui ont été confirmés pendant cette période et le nombre de clients qui ont partagé au moins une fois.",
          friendVisitsPerSharer: "Visites de filleuls par partageur",
          friendVisitsPerSharerContent:
            "Rapport entre le nombre de visites de filleuls (personnes invitées par vos clients) et les clients qui ont partagé au moins une fois.",
          friendVisitsToConfirmedReferral: "Visites de filleuls en parrainages confirmés",
          friendVisitsToConfirmedReferralContent:
            "Pourcentage de filleuls (personnes invitées par vos clients) devenus des parrainages confirmés au cours de la période sélectionnée.",
          referrersWhoShared: "Parrains qui ont partagé",
          referrersWhoSharedContent:
            "Pourcentage de ceux qui ont ensuite partagé au moins une fois avec leurs amis, parmi tous les clients qui se sont inscrits à votre programme de parrainage. Pour qu'ils soient considérés comme des partageurs, ils doivent avoir appuyé sur le bouton de partage ou copié le lien.",
        },
        tablist: {
          overview: "Aperçu",
          timeline: "Calendrier",
        },
        topbar: {
          buttonDownloadReportLoadingText: "Création en cours, veuillez patienter...",
          buttonDownloadReportText: "Télécharger",
          campaignSelector: {
            campaignHandlerTitle: "Campagne",
            deselectAll: "Tout désélectionner",
            campaignSelectorText: {
              multiple: "Sélection multiple",
              none: "Sélectionner une campagne",
            },
          },
          dateRangeSelector: {
            applyLinkText: "appliquer",
            customRangeSelector: {
              title: "Intervalle sélectionné",
              calendarMonth: {
                sunday: "D",
                monday: "L",
                tuesday: "M",
                wednesday: "M",
                thursday: "J",
                friday: "V",
                saturday: "S",
              },
            },
            helpText: {
              firstLine:
                "Sélectionnez un intervalle de dates personnalisé ou choissisez l'une des options prédéfinies à droite.",
              secondLine:
                "Si votre campagne est nouvelle, les données peuvent prendre un peu de temps pour se normaliser.",
            },
            presetsSelector: {
              lastMonth: "Dernier mois",
              lastSevenDays: "7 derniers jours",
              lastThirtyDays: "30 derniers jours",
              lastYear: "Dernière année",
              thisMonth: "Ce mois",
              thisYear: "Cette année",
              today: "Aujourd'hui",
              yesterday: "Hier",
            },
          },
          downloadErrorContent:
            "Le téléchargement a pris trop de temps et ne s'est pas terminé. Veuillez sélectionner un plus petit intervalle de dates ou réessayer.",
          downloadErrorTitle: "Échec du téléchargement",
          tooltipContent:
            "Ce rapport contient les données de la période que vous avez sélectionnée",
        },
      },
      navigation: {
        noMobileWarningTitle: "Le rapports ne sont pas accessibles sur les appareils mobiles.",
        noMobileWarningSubtitle: "Veuillez revisiter cette page en utilisant votre ordinateur.",
        operationalReports: "Rapports d'activité",
        reportingDashboard: "Tableau de bord des rapports",
        partnerReports: "Partenaires de Marque",
        referrerReports: "Programmes de Parrainage",
        rewardReports: "Distribution Automatisée de Récompenses",
      },
      management: {
        downloadCard: {
          buttonGenerateReport: {
            loading: "Création en cours, veuillez patienter...",
            hasReport: "Télécharger",
            noReport: "Générer & Télécharger",
          },
          generatedText: "Dernière création",
          neverGenaratedText:
            "Jamais généré avant. Selectionner un intervalle de date, puis cliquer sur générer et télécharger. Si le téléchargement échoue, essayer de réduire l'intervalle.",
          generateAgainButtonText: "Générer à nouveau",
          changeDateText:
            "Pour voir différentes dates, selectioner un nouvel intervalle de date, puis cliquer sur générer à nouveau. Si le téléchargement échoue, essayer de réduire l'intervalle.",
          errorSubmittedMessage:
            "Merci de vérifier que la date de commencement est inférieure à la date de fin et que l'intervalle se situe entre les limites autorisées par le rapport.",
          dateText: "Date",
        },
        header: "Rapports d'activité",
        navigation: {
          partner: {
            allDistributedVouchers: "Tous les bons d'achat distribués",
            allDistributedVouchersDescription:
              "Une liste de tous les bons d'achat distribués dans cette campagne.",
            allDistributedVouchersRecommendedRange: "Recommandé 1 an d'intervalle",
            customerSignups: "Inscription des clients",
            customerSignupsDescription:
              "Une liste complète de chaque client qui a choisi de devenir parrain, comprend les détails marketing, les URL de partage et tous les champs personnalisés.",
            customerSignupsRecommendedRange: "Recommandé 1 an d'intervalle",
            friends: "Filleuls",
            friendsDescription:
              "Tous les filleuls qui ont rempli leur coordonnés dans la page d'accueil du parrain (le cas échéant), ainsi que les filleuls qui ont effectué une transaction sur votre site via un parrainage.",
            friendsRecommendedRange: "Recommandé 90 jours d'intervalle",
            friendVouchers: "Bons d'achat des filleuls",
            friendVouchersDescription:
              "Récupérez tous les codes qui ont été distribué à des filleuls, y compris les bons d'achat après parrainage.",
            friendVouchersRecommendedRange: "Recommandé 90 jours d'intervalle",
            optOuts: "Les désabonnés",
            optOutsDescription: "Liste d'utilisateurs qui se sont désinscrits.",
            optOutsRecommendedRange: "Recommandé 1 an d'intervalle",
            fulfilments: "Rapport soulignant les actions complétées",
            fulfilmentsDescription:
              "Pour récupérer l'ensemble des événements complétés dans la période sélectionnée.",
            fulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardChoiceFulfilments: "Rapport sur les choix de récompense",
            rewardChoiceFulfilmentsDescription:
              "Pour récupérer le statut et les détails de toutes les récompenses créées dans la période sélectionnée.",
            rewardChoiceFulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardStreamLegacyReferralData: "Données de parrainage de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Une liste des parrainages (similaire au rapport qui a parrainé qui) formatée d'une façon similaire à ce qui était disponible dans RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommandé 90 jours d'intervalle",
            topReferrers: "Meilleurs Parrains",
            topReferrersDescription:
              "Une liste des 100 meilleurs parrainages de la campagne, classés par nombre de parrainages.",
            topReferrersRecommendedRange: "Recommandé 1 an d'intervalle",
            whoReferredWhom: "Qui a parrainé qui",
            whoReferredWhomDescription:
              "Récupérez une liste de tous les parrainages, le statut du parrainage, la date de la dernière mise à jour des données et si le parrainage a été effectué via pixel ou via bon d'achat.",
            whoReferredWhomRecommendedRange: "Recommandé 90 jours d'intervalle",
          },
          referrer: {
            allDistributedVouchers: "Tous les bons d'achat distribués",
            allDistributedVouchersDescription:
              "Une liste de tous les bons d'achat distribués dans cette campagne.",
            allDistributedVouchersRecommendedRange: "Recommandé 1 an d'intervalle",
            customerSignups: "Inscription des clients",
            customerSignupsDescription:
              "Une liste complète de chaque client qui a choisi de devenir parrain, comprend les détails marketing, les URL de partage et tous les champs personnalisés.",
            customerSignupsRecommendedRange: "Recommandé 1 an d'intervalle",
            friends: "Filleuls",
            friendsDescription:
              "Tous les filleuls qui ont rempli leur coordonnés dans la page d'accueil du parrain (le cas échéant), ainsi que les filleuls qui ont effectué une transaction sur votre site via un parrainage.",
            friendsRecommendedRange: "Recommandé 90 jours d'intervalle",
            friendVouchers: "Bons d'achat des filleuls",
            friendVouchersDescription:
              "Récupérez tous les codes qui ont été distribué à des filleuls, y compris les bons d'achat après parrainage.",
            friendVouchersRecommendedRange: "Recommandé 90 jours d'intervalle",
            optOuts: "Les désabonnés",
            optOutsDescription: "Liste d'utilisateurs qui se sont désinscrits.",
            optOutsRecommendedRange: "Recommandé 1 an d'intervalle",
            fulfilments: "Rapport soulignant les actions complétées",
            fulfilmentsDescription:
              "Pour récupérer l'ensemble des événements complétés dans la période sélectionnée.",
            fulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardChoiceFulfilments: "Rapport sur les choix de récompense",
            rewardChoiceFulfilmentsDescription:
              "Pour récupérer le statut et les détails de toutes les récompenses créées dans la période sélectionnée.",
            rewardChoiceFulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardStreamLegacyReferralData: "Données de parrainage de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Une liste des parrainages (similaire au rapport qui a parrainé qui) formatée d'une façon similaire à ce qui était disponible dans RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommandé 90 jours d'intervalle",
            topReferrers: "Meilleurs Parrains",
            topReferrersDescription:
              "Une liste des 100 meilleurs parrainages de la campagne, classés par nombre de parrainages.",
            topReferrersRecommendedRange: "Recommandé 1 an d'intervalle",
            whoReferredWhom: "Qui a parrainé qui",
            whoReferredWhomDescription:
              "Récupérez une liste de tous les parrainages, le statut du parrainage, la date de la dernière mise à jour des données et si le parrainage a été effectué via pixel ou via bon d'achat.",
            whoReferredWhomRecommendedRange: "Recommandé 90 jours d'intervalle",
          },
          reward: {
            allDistributedVouchers: "Tous les bons d'achat distribués",
            allDistributedVouchersDescription:
              "Une liste de tous les bons d'achat distribués dans cette campagne.",
            allDistributedVouchersRecommendedRange: "Recommandé 1 an d'intervalle",
            customerSignups: "Inscription des clients",
            customerSignupsDescription:
              "Une liste complète de chaque client qui a choisi de devenir parrain, comprend les détails marketing, les URL de partage et tous les champs personnalisés.",
            customerSignupsRecommendedRange: "Recommandé 1 an d'intervalle",
            friends: "Filleuls",
            friendsDescription:
              "Tous les filleuls qui ont rempli leur coordonnés dans la page d'accueil du parrain (le cas échéant), ainsi que les filleuls qui ont effectué une transaction sur votre site via un parrainage.",
            friendsRecommendedRange: "Recommandé 90 jours d'intervalle",
            friendVouchers: "Bons d'achat des filleuls",
            friendVouchersDescription:
              "Récupérez tous les codes qui ont été distribué à des filleuls, y compris les bons d'achat après parrainage.",
            friendVouchersRecommendedRange: "Recommandé 90 jours d'intervalle",
            optOuts: "Les désabonnés",
            optOutsDescription: "Liste d'utilisateurs qui se sont désinscrits.",
            optOutsRecommendedRange: "Recommandé 1 an d'intervalle",
            fulfilments: "Rapport soulignant les actions complétées",
            fulfilmentsDescription:
              "Pour récupérer l'ensemble des événements complétés dans la période sélectionnée.",
            fulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardChoiceFulfilments: "Rapport sur les choix de récompense",
            rewardChoiceFulfilmentsDescription:
              "Pour récupérer le statut et les détails de toutes les récompenses créées dans la période sélectionnée.",
            rewardChoiceFulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardStreamLegacyReferralData: "Données de parrainage de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Une liste des parrainages (similaire au rapport qui a parrainé qui) formatée d'une façon similaire à ce qui était disponible dans RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommandé 90 jours d'intervalle",
            topReferrers: "Meilleurs Parrains",
            topReferrersDescription:
              "Une liste des 100 meilleurs parrainages de la campagne, classés par nombre de parrainages.",
            topReferrersRecommendedRange: "Recommandé 1 an d'intervalle",
            whoReferredWhom: "Qui a parrainé qui",
            whoReferredWhomDescription:
              "Récupérez une liste de tous les parrainages, le statut du parrainage, la date de la dernière mise à jour des données et si le parrainage a été effectué via pixel ou via bon d'achat.",
            whoReferredWhomRecommendedRange: "Recommandé 90 jours d'intervalle",
          },
        },
        selectTitle: "Campagne",
      },
    },
    rewards: {
      header: "Récompenses",
      tableHeaders: {
        address: "Adresse",
        dateEarned: "Date d'acquisition",
        item: "Produit",
        source: "Source",
        status: "Statut",
      },
      actions: {
        claimSubmitted: "Demande soumise",
        clickToClaimOption: "cliquez pour réclamer",
        errorRewardEmailResenMessage:
          "Il y a eu un problème lors de l'envoi de l'email pour cette récompense.",
        errorSubmittedMessage: "Il y a eu un problème dans la mise à jour de cette demande",
        resentOption: "Renvoyer l'email de récompense",
        successRewardEmailResentMessage: "Email de récompense renvoyé",
        successSubmittedMessage: "Demande soumise avec succès",
      },
    },
    statuses: {
      cancelled: "annulé",
      complete: "complété",
      failed: "échoué",
      fulfilled: "complété",
      pending: "en attente",
      processed: "traité",
      processing: "en cours",
      redeemed: "utilisé",
      waiting: "en attente",
      expired: "expired",
      paused: "en pause",
    },
    subscriptions: {
      header: "Abonnements",
      tableHeaders: {
        activationDate: "Date d'activation",
        identifier: "Identifiant",
        plan: "Plan",
        status: "Statut",
      },
    },
    partners: {
      header: "Partenaires",
      tableHeader: {
        businessName: "nom",
        email: "email",
        contactName: "nom du contact",
      },
      programPerformance: {
        header: "Performance",
        info: "Ce tableau de bord montre la performance de tous les partenaires",
        tableHeaders: {
          month: "Mois",
          visits: "Visites",
          created: "Créés",
          confirmed: "Confirmés",
          cancelled: "Annulés",
          rewardsConfirmed: "Récompenses confirmées",
        },
        tooltips: {
          visits: "Combien de personnes ont visité la page de destination de vos partenaires",
          created: "Combien de recommandations ont été créées par vos partenaires",
          confirmed: "Combien de recommandations ont été confirmées par vos partenaires",
          cancelled: "Combien de recommandations ont été annulées",
        },
        tableTotal: "Total",
        partnerFilter: {
          selectAll: "Tous les partenaires",
        },
        downloadCsvButton: {
          loading: "Création en cours, veuillez patienter…",
          text: "Télécharger le rapport (CSV)",
        },
        partnerErrors:
          "Un problème est survenu lors du chargement des filtres. Veuillez réessayer plus tard.",
      },
    },
    addPartner: {
      prompt: "Ajouter un partenaire",
      modal: {
        successMessage: "Partenaire créé avec succès",
      },
    },
    editContent: {
      prompt: "Modifier le contenu",
      title: "Modifier le contenu du partenaire",
      description:
        "Pour modifier le contenu de plusieurs partenaires, veuillez sélectionner dans la liste ci-dessous",
    },
    partnerProfile: {
      profile: "Profil",
      createFormIntro: "Ajouter des détails du partenaire",
      editFormIntro: "Modifier les détails du partenaire",
      audienceInfo:
        "Cette information vous aide à comprendre la taille de l'audience/contacts du partenaire",
      formCampaignInfo:
        "Choisissez les campagnes auxquelles vous souhaitez que le partenaire participe",
      contentInfo:
        "C'est ici que vous pouvez personnaliser le contenu de la page de profil du partenaire",
      rewardsInfo: "Choisissez les récompenses disponibles pour le partenaire et son audience",
      campaignsInfo: "Voir à quelle(s) campagne(s) le partenaire appartient",
      createReferral: "Créer une recommandation",
      editProfile: {
        prompt: "Modifier le profil du partenaire",
        successTitle: "Succès",
        successMessage: "Succès ! Vous avez mis à jour le profil du partenaire avec succès !",
      },
      editSettings: "Modifier les paramètres",
      audience: "Audience du partenaire",
      firstSeen: "Première visite",
      lastSeen: "Dernière visite",
      dateEnrolled: "Date d'inscription",
      contactName: "Nom du contact",
      displayName: "Afficher le nom",
      displayNameTooltip:
        "C'est ainsi que le nom de votre marque/entreprise sera affiché sur votre page publique",
      quote: "Votre slogan",
      quoteTooltip:
        "Veuillez entrer une courte description de votre marque/entreprise et la raison pour laquelle vous êtes partenaire de ce programme. Cela sera affiché sur votre page de destination d'audience.",
      offerHeader: "Titre de la page de profil du partenaire",
      offerHeaderTooltip: "C'est le titre qui apparaîtra sur la page de profil du partenaire",
      assets: "Contenus",
      informationContent: "Contenu de la page de profil",
      assetsDescription:
        "Complétez votre profil en ajoutant des éléments descriptifs et visuels (par exemple, nom, profil, bannière et votre slogan)",
      bannerImage: "Image de la bannière",
      logoImage: "Logo",
      addAssets: {
        prompt: "Ajouter du contenu",
        editPrompt: "Modifier le contenu",
        sectionOneHeading: "Éléments descriptifs",
        sectionOneDescription: "Veuillez ajouter votre nom et slogan",
        sectionTwoHeading: "Éléments visuels",
        sectionTwoDescription:
          "Veuillez télécharger votre logo et l'image de la bannière pour votre page publique",
        logoImporterHeader: "Téléchargez votre logo ici",
        logoImporterInfo: "Recommandations relatives aux dimensions: 48 x 48 pixels",
        bannerImporterHeader: "Téléchargez votre bannière ici",
        bannerImporterInfo: "Recommandations relatives aux dimensions: 350 x 415 pixels",
        importerButtonText: "Parcourir les fichiers",
        successTitle: "Vos éléments ont été publiés avec succès",
        successMessage:
          "Vous pouvez maintenant afficher et modifier votre profil et explorer d'autres fonctionnalités",
        viewProfile: "Voir le profil",
        congratulations: "Félicitations !",
        quotePlaceholder:
          "Veuillez entrer une courte description de votre marque/entreprise et la raison pour laquelle vous êtes partenaire de ce programme",
        offerHeaderPlaceholder: "Veuillez fournir le titre pour la page de profil du partenaire",
        assetsModal: {
          header: "Ajouter du contenu pour",
          notice: "Aucun contenu défini pour",
          instruction:
            "Sélectionnez la langue à utiliser comme base pour commencer à rédiger le contenu",
          copyButton: "Copier depuis",
        },
      },
      activate: "Activer",
      createReferrals: "Créer des recommandations",
      campaigns: "Campagne(s) active(s)",
      campaignDetail: "Détails de la campagne",
      campaignName: "Nom de la campagne",
      campaignDateRegistered: "Date d'inscription du partenaire",
      campaignShareUrlText: "Lien unique de l'offre du partenaire",
      campaignDashboardUrlText: "Lien vers le tableau de bord du partenaire",
      campaignCopyButtonText: "Copier",
      rewards: {
        title: "Récompenses",
        setRewards: "Définir les récompenses",
        editRewards: "Modifier les récompenses",
        errorLoadingRewards: "Erreur de chargement des récompenses, veuillez rafraîchir la page.",
        instructionsTitle: "Gérer les récompenses",
        instructions: "Ajouter, modifier ou supprimer des récompenses.",
        partnerReward: "Récompense pour le partenaire",
        audienceReward: "Récompense pour l'audience",
        unconfiguredWarning:
          "Toutes les options de récompense sont disponibles par défaut jusqu'à ce qu'une sélection soit effectuée",
        form: {
          partnerRewardLabel: "Récompense pour le partenaire",
          audienceRewardLabel: "Récompense pour l'audience",
          rewardPlaceholder: "Sélectionnez la(les) récompense(s)",
        },
      },
      errorLoadingText: "Il y a eu un problème lors du chargement des données du partenaire",
      loadingText:
        "Cette page contient de nombreuses recommandations et peut mettre un certain temps à se charger",
      performance: "Performance",
      performanceSubHeading: "Ce rapport montre la performance du partenaire",
      downloadPerformanceCsv: "Télécharger le rapport (CSV)",
    },
    userProfile: {
      confirmAlreadyExistingFriend: {
        content:
          "Veuillez confirmer que vous souhaitez traquer un nouveau parrainage vers ce filleul existant",
      },
      createReferralButtonText: "Créer un parrainage",
      createReferralModal: {
        title: "Créer un parrainage",
        friendExists: "Ce filleul existe déjà",
      },
      errorLoadingText: "Il y a eu un problème dans le chargement des données de l'utilisateur",
      editDetailsText: "Modifier les détails",
      loadingText:
        "Cette page contient de nombreux parrainages et peut prendre un certain temps à se charger",
      identifier: "Identifiant",
      issueNewRewardText: "Délivrer des nouvelles récompenses",
      issueNewRewardModal: {
        campaignText: "Campagne",
        errorMessage:
          "Il y a eu un problème dans la création de cette récompense, veuillez réessayer plus tard.",
        errorLimitMessage:
          "Vous avez atteint la limite des récompenses que vous pouvez délivrer sur une période de 24h",
        prompt: "Êtes-vous certain de vouloir délivrer cette récompense ?",
        recipientText: "Destinataire",
        rewardInfo: {
          isCustomer:
            "Si cette campagne récompense le filleul qui a été parrainé, vous devrez lui délivrer également une récompense.",
          isExternalUser:
            "Si cette campagne récompense le parrain qui a effectué le parrainage, vous devrez lui délivrer également une récompense.",
        },
        rewardTypes: {
          archived: "Archivé",
          inactive: "Inactif",
        },
        rewardText: "Récompense",
        subtitle: "Seulement 10 récompenses par jour peuvent être délivrées manuellement.",
        successMessage: "Récompense délivrée avec succès à ",
        title: "Délivrance de la récompense ",
      },
      firstSeen: "première connexion",
      lastSeen: "dernière connexion",
      shareURL: "Partager l'URL",
      successReferralCreationMessage: "Le parrainage a été créé avec succès.",
      unsubscribeFromEmailsText: "Se désabonner des emails",
      unsubscribeModal: {
        closeModalText: "Non, Annuler",
        errorMessage: "Il y a eu un problème dans la mise à jour de cet utilisateur",
        prompt: "Oui, se désabonner",
        successMessage: "Vous vous êtes désabonné avec succès",
        title: "Êtes-vous certain de vouloir désabonner {{fullName}} de tous les emails?",
      },
    },
    referrerProfile: {
      title: "Profil de l'utilisateur",
      issueNewRewardText: "Émettre une nouvelle récompense",
      createReferralButtonText: "Créer une recommandation",
      editDetailsButton: "Modifier les détails",
      editButton: "Modifier",
      editCustomFieldTitle: "Campagne :",
      friendSectionTitle: "Détails de l'ami",
      referrerSectionTitle: "Marketing de recommandation",
      manualSectionTitle: "Paiements manuels reçus",
      rewardSectionTitle: "Distribution des récompenses",
      partnerSectionTitle: "Partenaires de Marque",
      newUiBanner: {
        prompt: "Essayez notre nouvelle gestion améliorée des clients",
        on: "Activé",
        off: "Désactivé",
        demoLinkLabel: "Regarder le tutoriel",
        demoLink: "https://app.supademo.com/demo/cm24rze1t2sv513b3avst3fj5",
      },
      referrerInfoCard: {
        loading: "Chargement...",
        name: "Nom",
        rewardsEarned: "Récompenses gagnées",
        referredBy: "Parrainé par",
        emailAddress: "Email",
        emailStatus: "Statut des emails de la campagne",
        emailSubscribed: "Abonné",
        emailUnsubscribed: "Désabonné",
        locale: "Langue",
        lastSeen: "Vu pour la dernière fois le",
        dateRegistered: "Date d'inscription",
        campaign: "Campagne",
        shareUrl: "Partager l'URL",
        copyShareUrl: "Copier l'URL à partager",
        dashboardUrl: "URL du tableau de bord",
        copyDashboardUrl: "Copier l'URL du tableau de bord",
        referralsMade: "Recommandations réalisées",
        rewardCapNotSet: "Non défini",
        cappingTypeTitle: "Type de plafond",
        cap: "plafond",
        cappingPeriod: {
          lifetime: "Durée de vie",
          calendar_year: "Annuellement",
          twelve_months: "Les douze derniers mois",
        },
        cappingType: {
          monetary: "valeur",
          volume: "volume",
        },
      },
      referralInfoCard: {
        campaign: "Campagne",
        emailAddress: "Email",
        rewardName: "Nom & Type de la récompense",
        rewardValue: "Valeur de la récompense",
        rewardValueNotSet: "Non défini",
        rewardCode: "Code de récompense",
        fulfilmentDate: "Date de réalisation",
        rewardStatus: "Statut de la récompense",
        referralName: "Nom",
        referralDate: "Date de la recommandation",
        referralStatus: "Statut de la recommandation",
        rewardFulfilmentLog: "Journal de réalisation des récompenses",
        referralHistoryLog: "Journal des historiques de recommandation",
        orderNumber: "Numéro de commande",
        orderDate: "Date de la commande",
      },
      subscribeEmail: {
        successMessage: "abonné avec succès",
        errorMessage: "Un problème est survenu lors de la mise à jour de cet utilisateur",
      },
      unsubscribeModal: {
        closeText: "Non",
        errorMessage: "Un problème est survenu lors de la mise à jour de cet utilisateur",
        prompt: "Oui, se désabonner",
        title: "Se désabonner des emails & marketing",
        subtitle: "Êtes-vous sûr de vouloir désabonner {{fullName}} de tous les emails ?",
      },
      subscribeModal: {
        closeText: "Non",
        errorMessage: "Un problème est survenu lors de la mise à jour de cet utilisateur",
        prompt: "Oui, s'abonner",
        title: "S'abonner aux emails & marketing",
        subtitle: "Êtes-vous sûr de vouloir abonner {{fullName}} à tous les emails ?",
      },
      statuses: {
        cancelled: "Annulé",
        complete: "Complet",
        expired: "Expiré",
        failed: "Échoué",
        paused: "En pause",
        pending: "En attente",
        processing: "En cours de traitement",
        redeemed: "Utilisé",
      },
      referralTimeline: {
        error: "Erreur",
        paymentProviderError: "Erreur du fournisseur de paiement",
        reward_failed: "Échec de la récompense",
        reward_failed_description: "Cette récompense n'a pas pu être émise",
        reward_complete: "Récompense complète",
        reward_complete_description: "Cette récompense a été émise avec succès",
        reward_processing: "Traitement de la récompense",
        reward_processing_description: "Cette récompense est en cours de traitement",
        reward_pending: "Récompense en attente",
        reward_pending_description: "Cette récompense est en attente",
        reward_paused: "Récompense en pause",
        reward_paused_description: "Cette récompense est en pause",
        reward_redeemed: "Récompense utilisée",
        reward_redeemed_description: "Cette récompense a été utilisée",
        reward_expired: "Récompense expirée",
        reward_expired_description: "Cette récompense a expiré",
        reward_cancelled: "Récompense annulée",
        reward_cancelled_description: "Cette récompense a été annulée",
        referral_failed: "Échec de la recommandation",
        referral_failed_description: "Cette recommandation n'a pas pu être traitée",
        referral_complete: "Recommandation complète",
        referral_complete_description: "Cette recommandation a été traitée avec succès",
        referral_processing: "Traitement de la recommandation",
        referral_processing_description: "Cette recommandation est en cours de traitement",
        referral_pending: "Recommandation en attente",
        referral_pending_description: "Cette recommandation est en attente",
        referral_paused: "Recommandation en pause",
        referral_paused_description: "Cette recommandation est en pause",
        referral_expired: "Recommandation expirée",
        referral_expired_description: "Cette recommandation a expiré",
        referral_cancelled: "Recommandation annulée",
        referral_cancelled_description: "Cette recommandation a été annulée",
        timeProcessed: "Heure traitée",
        timeProcessed_description: "L'heure à laquelle la récompense a été traitée",
        transactionId: "ID de la transaction",
        actionDescriptor: "Veuillez sélectionner une action disponible",
        actionDescriptor_description: "Les actions disponibles pour cette récompense",
        confirm: "Confirmer",
        dataUnavailable: "Données indisponibles",
        dataUnavailableDescription:
          "L'historique détaillé est indisponible pour les transactions avant juillet 2024",
        dataUnavailableSupportInfo:
          "Veuillez consulter les rapports disponibles pour plus d'informations",
        emailTitle_signed_up_processed: "Email d'instructions envoyé",
        emailTitle_signed_up_delivered: "Email d'instructions livré",
        emailTitle_signed_up_open: "Email d'instructions ouvert",
        emailTitle_signed_up_click: "Email d'instructions cliqué",
        emailTitle_signed_up_bounce: "Email d'instructions a rebondi",
        emailTitle_signed_up_dropped: "Email d'instructions abandonné",
        emailTitle_friend_post_purchase_tracking_processed: "Email post-achat envoyé",
        emailTitle_friend_post_purchase_tracking_delivered: "Email post-achat livré",
        emailTitle_friend_post_purchase_tracking_open: "Email post-achat ouvert",
        emailTitle_friend_post_purchase_tracking_click: "Email post-achat cliqué",
        emailTitle_friend_post_purchase_tracking_bounce: "Email post-achat a rebondi",
        emailTitle_friend_post_purchase_tracking_dropped: "Email post-achat abandonné",
        emailTitle_friend_reward_processed: "Email de récompense envoyé",
        emailTitle_friend_reward_delivered: "Email de récompense livré",
        emailTitle_friend_reward_open: "Email de récompense ouvert",
        emailTitle_friend_reward_click: "Email de récompense cliqué",
        emailTitle_friend_reward_bounce: "Email de récompense a rebondi",
        emailTitle_friend_reward_dropped: "Email de récompense abandonné",
        emailTitle_reward_delivered: "Email de récompense livré",
        emailTitle_reward_processed: "Email de récompense envoyé",
        emailTitle_reward_open: "Email de récompense ouvert",
        emailTitle_reward_click: "Email de récompense cliqué",
        emailTitle_reward_bounce: "Email de récompense a rebondi",
        emailTitle_reward_dropped: "Email de récompense abandonné",
        emailTitle_manual_reward_processed: "Email de récompense manuelle envoyé",
        emailTitle_manual_reward_delivered: "Email de récompense manuelle livré",
        emailTitle_manual_reward_open: "Email de récompense manuelle ouvert",
        emailTitle_manual_reward_click: "Email de récompense manuelle cliqué",
        emailTitle_manual_reward_bounce: "Email de récompense manuelle a rebondi",
        emailTitle_manual_reward_dropped: "Email de récompense manuelle abandonné",
        emailTitle_referrer_inactive_nurture_processed: "Email de parrain inactif envoyé",
        emailTitle_referrer_inactive_nurture_delivered: "Email de parrain inactif livré",
        emailTitle_referrer_inactive_nurture_open: "Email de parrain inactif ouvert",
        emailTitle_referrer_inactive_nurture_click: "Email de parrain inactif cliqué",
        emailTitle_referrer_inactive_nurture_bounce: "Email de parrain inactif a rebondi",
        emailTitle_referrer_inactive_nurture_dropped: "Email de parrain inactif abandonné",
        emailTitle_referrer_nurture_processed: "Email de parrainage envoyé",
        emailTitle_referrer_nurture_delivered: "Email de parrainage livré",
        emailTitle_referrer_nurture_open: "Email de parrainage ouvert",
        emailTitle_referrer_nurture_clicked: "Email de parrainage cliqué",
        emailTitle_referrer_nurture_bounce: "Email de parrainage a rebondi",
        emailTitle_referrer_nurture_dropped: "Email de parrainage abandonné",
        emailTitle_click_to_claim_processed: "Email pour réclamer envoyé",
        emailTitle_click_to_claim_delivered: "Email pour réclamer livré",
        emailTitle_click_to_claim_open: "Email pour réclamer ouvert",
        emailTitle_click_to_claim_click: "Email pour réclamer cliqué",
        emailTitle_click_to_claim_bounce: "Email pour réclamer a rebondi",
        emailTitle_click_to_claim_dropped: "Email pour réclamer abandonné",
        emailTitle_incentive_processed: "Email d'incitation envoyé",
        emailTitle_incentive_delivered: "Email d'incitation livré",
        emailTitle_incentive_open: "Email d'incitation ouvert",
        emailTitle_incentive_click: "Email d'incitation cliqué",
        emailTitle_incentive_bounce: "Email d'incitation a rebondi",
        emailTitle_incentive_dropped: "Email d'incitation abandonné",
        emailTitle_referred_but_no_reward_yet_processed:
          "Recommandation suivie - email en attente d'approbation envoyé",
        emailTitle_referred_but_no_reward_yet_delivered:
          "Recommandation suivie - email en attente d'approbation livré",
        emailTitle_referred_but_no_reward_yet_open:
          "Recommandation suivie - email en attente d'approbation ouvert",
        emailTitle_referred_but_no_reward_yet_click:
          "Recommandation suivie - email en attente d'approbation cliqué",
        emailTitle_referred_but_no_reward_yet_bounce:
          "Recommandation suivie - email en attente d'approbation a rebondi",
        emailTitle_referred_but_no_reward_yet_dropped:
          "Recommandation suivie - email en attente d'approbation abandonné",
        emailTitle_click_to_claim_friend_processed: "Email de réclamation pour ami envoyé",
        emailTitle_click_to_claim_friend_delivered: "Email de réclamation pour ami livré",
        emailTitle_click_to_claim_friend_open: "Email de réclamation pour ami ouvert",
        emailTitle_click_to_claim_friend_click: "Email de réclamation pour ami cliqué",
        emailTitle_click_to_claim_friend_bounce: "Email de réclamation pour ami a rebondi",
        emailTitle_click_to_claim_friend_dropped: "Email de réclamation pour ami abandonné",
        emailTitle_generic_processed: "Email de récompense renvoyé",
        emailTitle_pending_referral_processed: "Email de recommandation en attente envoyé",
        emailTitle_pending_referral_delivered: "Email de recommandation en attente livré",
        emailTitle_pending_referral_open: "Email de recommandation en attente ouvert",
        emailTitle_pending_referral_click: "Email de recommandation en attente cliqué",
        emailTitle_pending_referral_bounce: "Email de recommandation en attente a rebondi",
        emailTitle_pending_referral_dropped: "Email de recommandation en attente abandonné",
        selectPlaceholder: "Sélectionnez une action",
      },
    },
    fulfilmentFailureReasons: {
      referral_volume_limit_reached: "Limite de volume de parrainages atteinte",
    },
    userResults: {
      noResultMessage:
        "Désolé, nous avons trouvé <strong>0 résultat</strong> pour <strong>{{searchTerm}}</strong>. Veuillez essayer une autre recherche.",
      tableHeader: {
        name: "Nom",
        email: "Email",
        identifier: "Identifiant",
        type: "type",
        referrals: "parrainages",
      },
      userType: {
        friend: "filleul",
        referrer: "parrain",
      },
    },
    users: {
      navigation: {
        bulkActions: "Actions groupées",
        customerManagement: "Gestion client",
        partnerManagement: "Gestion partenaire",
      },
    },
    userSearch: {
      addReferrer: {
        prompt: "Ajouter un parrain",
        modal: {
          successMessage: "Parrain créé avec succès",
          form: {
            selectCampaignText: "Sélectionnez une campagne",
            emailComplianceLabelText:
              "Cochez cette case pour confirmer que ce parrain accepte qu'il n'envoie des messages de parrainage qu'aux personnes avec lesquelles il a un relation personelle ou familiale.",
            marketingOptInLabelText:
              "Cochez cette case pour confirmer que vous avez le consentement de ce parrain à l'inscrire à votre campagne marketing",
            termAndConditionsLabelText:
              "Cochez cette case pour confirmer que ce parrain a accepté les conditions générales.",
          },
        },
      },
      searchResult: "{{count}} résultats",
      searchResult_one: "{{count}} résultat",
      searchInstructionPrompt:
        "Recherchez le nom du client, son adresse email, son numéro de commande ou tout autre champ personnalisé que vous avez configuré.",
    },
    vouchers: {
      header: "Bons d'achat",
      tableHeaders: {
        manuallyIssued: "Délivré manuellement",
        obtainedThrough: "Obtenu par",
        redeemed: "Réclamé?",
        redeemedBy: "Réclamé par",
        voucher: "Bon d'achat",
      },
    },
    paragon: {
      integrations: "Intégrations",
      connectionError: "Il y a eu un problème de connexion à ce service.",
      connected: "Connecté",
      notConnected: "Non connecté",
      connectedDescription:
        "Gérez votre campagne plus efficacement en naviguant dans les paramètres d'intégration.",
      notConnectedDescription:
        "Connectez et synchronisez vos {{integrationName}} comptes, contacts et prospects.",
      connect: "Connecter",
      settings: "Paramètres d'intégration",
    },
  },
};
