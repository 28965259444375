import React, { useCallback, useState } from "react";

import { useAuth } from "@/AuthWrapper";
import { Header } from "bp-ui";
import { Icons } from "bp-ui";
import { Permissions } from "bp-ui";
import { fullName } from "helpers/Helpers";
import { languages } from "locales";
import { Settings } from "luxon";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FaBullhorn, FaUser } from "react-icons/fa6";
import { NavLink, useLocation } from "react-router-dom";

const Navigation = ({ selectedMarket, backButton = false }) => {
  const { t, i18n } = useTranslation();
  const { currentAdmin, logout } = useAuth();
  const location = useLocation();
  const { viewReports, accessCms } = Permissions;

  const getActiveLanguage = useCallback(
    () => languages.find((language) => language.value === i18n.resolvedLanguage),
    [i18n.resolvedLanguage]
  );

  const pathActive = (path, location) => {
    const pathToMatch = path.split("/");
    const currentPath = location.pathname.split("/");

    if (pathToMatch.length < 2 || pathToMatch.length < 2) {
      return false;
    }

    return pathToMatch[2] === currentPath[2];
  };

  const campaignLink = `${import.meta.env.VITE_PORTAL_CMS_URL}/${selectedMarket.subdomain}`;
  const usersLink = `/${selectedMarket.subdomain}/users/search`;
  const reportsLink = `/${selectedMarket.subdomain}/reports`;

  const availableLinks = [
    {
      icon: FaBullhorn,
      text: t("global.campaigns"),
      path: campaignLink,
      active: false,
      permissions: [accessCms],
    },
    {
      icon: FaUser,
      text: t("global.customers"),
      path: usersLink,
      active: pathActive(usersLink, location),
      permissions: [],
      linkElement: NavLink,
      linkElementProps: {
        to: usersLink,
      },
    },
    {
      icon: Icons.Reports,
      text: t("global.reporting"),
      path: reportsLink,
      active: pathActive(reportsLink, location),
      permissions: [viewReports],
      linkElement: NavLink,
      linkElementProps: {
        to: reportsLink,
      },
    },
  ];

  const authorisedLinks = currentAdmin.super
    ? availableLinks
    : availableLinks.filter((link) => currentAdmin.canPerformAnyOf(link.permissions));

  const userMenuLinks = [
    {
      text: t("cta.changeMarket"),
      linkElement: NavLink,
      linkElementProps: {
        to: "/",
        className: "cursor-pointer text-mid-blue underline text-base hover:text-dark-blue",
      },
    },
    {
      text: t("cta.signOut"),
      onClick: () => logout(),
    },
  ];

  let identity = fullName(currentAdmin.firstName, currentAdmin.lastName);
  if (identity.length === 0) {
    identity = currentAdmin.email;
  }

  return (
    <Header
      links={authorisedLinks}
      userMenuLinks={userMenuLinks}
      languages={languages}
      activeLanguage={getActiveLanguage()}
      userName={identity}
      marketName={selectedMarket.name}
      onLanguageChange={(language) => {
        i18n.changeLanguage(language.value);

        if (
          window.navigator.language.startsWith("en") &&
          language.value !== window.navigator.language &&
          language.value.startsWith("en")
        ) {
          Settings.defaultLocale = window.navigator.language;
        } else {
          Settings.defaultLocale = language.value;
        }
      }}
    />
  );
};

Navigation.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
  backButton: PropTypes.bool,
};

export default Navigation;
